.list_logo_two .col_6.active{
    background: rgba(68, 68, 68, 0.3);
}
.list_logo_two .col_6.active a.yb_rot h3{
    color: #fff;
}
ul.seven_column li:hover,
ul.seven_column li.active {
    border: 1px solid #707070;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}
.inner_container{
    position: relative;
    max-width: 1417px;
    width: 100%;
    margin: auto;
}

ul.seven_column {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin-top: 30px;
}

ul.seven_column li {
    width: 12.5%;
    list-style: none;
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    border-radius: 10px;
    padding: 30px 0;
    margin-right: 25px;
    margin-bottom: 25px;
}

ul.seven_column li h4 {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
/* identical to box height */
    text-align: center;
    color: #373737;
    padding-top: 14px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}

ul.seven_column li a {
    display: inline-block;
    text-decoration: none;
    width: 100%;
    height: 100%;
    text-align: center;
}

ul.seven_column li:nth-child(7n) {
    margin-right: 0;
}
ul.seven_column li a img{
    width: 65px;
    height: 65px;
    object-fit: contain;
}
.find_category_section {
    margin-bottom: 100px;
}

@media (max-width: 1450px){
    ul.seven_column li{
        width: 12.5%;
        margin-right: 20px;
        margin-bottom: 20px;
    }

}

@media (max-width: 1280px){
    ul.seven_column li{
        width: 17%;
    }
    ul.seven_column li:nth-child(7n){
        margin-right: 20px;
    }
    ul.seven_column li:nth-child(5n) {
        margin-right: 0;
    }
}

@media (max-width: 980px){

    ul.seven_column li:nth-child(7n) {
        margin-right: 20px;
    }
    ul.seven_column li:nth-child(5n){
        margin-right: 20px;
    }
    ul.seven_column li:nth-child(3n) {
        margin-right: 0;
    }
    ul.seven_column li{
        width: 30%;
    }

}

@media (max-width: 650px){
    body ul.seven_column li {
        width: 100%;
        margin-right: 0;
    }
    ul.seven_column li:nth-child(7n),
    ul.seven_column li:nth-child(5n) {
        margin-right: 0px;
    }


}