.news_post{display: flex;align-items: center; border-bottom: 1px solid #D6D6D6; padding: 60px 0 !important;}
.news_post:first-child{padding-top:0;}
.news_post .news_text h2{font-weight: 700;font-size: 18px; line-height: 21.13px;}
.news_post .news_text h2 a{color:#444444;text-decoration: none;}
.news_post .news_text p{font-weight: 400;font-size: 14px; line-height:21.55px;color:#444444;}
.news_text{margin-left: 30px;width: calc(100% - 437px);}
.news_section .row.inner_row .news {display: flex;justify-content: flex-end;margin-bottom: 0; }
.inner_row_news{margin:0 35px}
a.news_btn {width: 209px;height: 47px;background: #EEE6B8;border-radius: 8.28358px;font-style: normal;font-weight: 700;font-size: 14.9104px;line-height: 18px;text-align: center;color: #444444;display: inline-block;text-decoration: none;text-align: center;display: flex;align-items: center;justify-content: center;}
.news_img img {width: 100%;height: 100%;object-fit: cover;border-radius: 10px;}
.news_img {max-width: 407px;height: 218px;width: 100%;}


.padding-0-10{
    padding: 0 10px;
}
/* -------- screen size 980px css---------- */

@media(max-width:980px){
body .news_post{flex-direction: column;padding: 30px 0 !important;}
.news_post .news_text p{margin-bottom: 0px;}
.news_text{width: auto;text-align: center;padding-top: 30px; margin-left: 0px;}
.news_img{margin-bottom: 30px;}
.news_img img{width: 100%;}
}

/* -------- screen size 650px css---------- */

@media (max-width: 650px){
body .heading_section {flex-direction: column-reverse;}
.news_section .row.inner_row .news {justify-content: left;padding: 0;margin-bottom: 27px;}
}