.evnetmail_popup input{font-size: 13px;color: #494949;}
.button_wapper_evnet .btn{max-width: 170px;}
.atten_btn button.btn.login_btn:nth-child(1) {margin-right: 12px;}
.evnetmail_popup .modal-dialog{display: flex;justify-self: center;align-items: center;height: 100%;}



.event_detail_section .img_box_event{width: 100%;max-width: 672px;}
.event_detail_section .img_box_event img{width: 100%;height: 100%;object-fit: cover;}
.event_detail_section .event_text p{font-weight: 500;font-size: 14px;line-height: 153.9%;color: #444444;padding-bottom: 10px;}
.event_detail_section .event_textdetail h4{margin-bottom: 0; font-style: normal;font-weight: 700;font-size: 15px;line-height: 165.4%;color: #494949;}
.event_detail_section .event_textdetail p a,
.event_detail_section .event_textdetail p{padding-bottom: 25px;margin-bottom: 0; font-style: normal;font-weight: 400;font-size: 15px;line-height: 165.4%;color: #494949;}
.event_detail_section .event_textdetail,
.event_detail_section .event_text{padding-right: 50px;}



@media only screen and (max-width: 980px){

    body .event_detail_section .col-sm-5,
    body .event_detail_section .col-sm-7{width: 100%;}
    .event_detail_section .event_textdetail, 
    .event_detail_section .event_text{padding-right: 0;}
    .event_detail_section .event_textdetail{margin-bottom: 30px;}

}