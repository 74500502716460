.contact_section .row.inner_row ul.social_icon {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0;
}
ul.social_icon li a{background: #fdfdfd;box-shadow: 0px 2.18898px 5.10763px rgba(0, 0, 0, 0.05);width: 100%;height: 48.59px;max-width: 48.59px;border-radius: 100%;overflow: hidden;}
ul.social_icon li{list-style: none;margin: 0 10px;display: inline-block;width: 100%;max-width: 51px;}
ul.social_icon li a{text-decoration: none;background-repeat: no-repeat;display: inline-block;align-items: center;justify-content: center;margin: auto;background-position: center;color: #fff0;}
ul.social_icon li.facebook a{background-image: url(../../../public/images/facebook.png);}
ul.social_icon li.insta a{background-image: url(../../../public/images/insta.png);}
ul.social_icon li.linkedin a{background-image: url(../../../public/images/linkedin.png);}
ul.social_icon li:hover a{background-color: #A3A3A3;box-shadow: 0px 2.18898px 5.10763px rgba(0, 0, 0, 0.05);}
ul.social_icon li:hover.facebook a{background-image: url(../../../public/images/facebook_white.png);}
ul.social_icon li:hover.insta a{background-image: url(../../../public/images/insta_white.png);}
ul.social_icon li:hover.linkedin a{background-image: url(../../../public/images/linkedin_white.png);}
.icon_section{align-items: center;}
.contact_section .row.inner_row h3{margin-bottom: 0;}
body .right_btn_wapper button {
    width: auto;
    padding: 15px 32px;
}
.right_btn_wapper .form-group {
    float: right;
}

.padding-0-10 {
    padding: 0 10px;
}

@media (max-width: 650px) {
    body .contact_section .right_btn_wapper {
        margin-bottom: 30px;
    }
    body .right_btn_wapper .form-group,
    body .right_btn_wapper {float: left;width: 100%;margin-top: 12px;}
    body .icon_section .col-sm-6 {width: 100%;}
    .contact_section .row.inner_row ul.social_icon{justify-content: left;padding: 0;margin-bottom: 27px;}
    body .icon_section{    flex-direction: column-reverse;}
    .contact_section  .form_area .col-sm-3{width: 100%;}

}