
.register_form .modal.fade .modal-dialog {
    max-width: 958px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: auto;
}


.register_form .user_card .row  .form-group {
    padding: 0 10px;
}
.register_form span.red_require {color: red;font-weight: 600;padding-left: 2px;}

.register_form .user_card .row:nth-child(1){margin-bottom: 20px;}
.register_form .user_card .justify-content-center{flex-wrap: wrap;align-items: center;}
.register_form .user_card .justify-content-center h3 {
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    color: #444444;
    padding: 20px 0;
    width: 100%;
}
.yi_form_bottom .form-group.flex_center button {
    width: auto;
    padding: 15px 49px;
    margin-left: 16px;
}
.yi_form_bottom .form-group.flex_center {
    justify-content: right;
}

body .reg_inputFile {
    border: none !important;
}

/* Responsive Media Query */
@media (max-width: 650px) {

.register_form .modal-content{height: 600px;overflow-y: auto;overflow-x: hidden;}
body .row.flex_center {
    flex-wrap: wrap !important;
}
.yi_form_bottom .form-group.flex_center button{padding: 15px 40px;}
.yi_form_bottom .form-group.flex_center {
    justify-content: left;
    margin-top: 12px;
}

}