.profile_form .modal.fade .modal-dialog {max-width: 958px;width: 100%;display: flex;align-items: center;justify-content: center;height: 100%;margin: auto;}
.profile_form .user_card .row  .form-group { padding: 0 10px;}
/* .profile_form .user_card .row:nth-child(1){margin-bottom: 20px;} */
.profile_form .user_card .justify-content-center{flex-wrap: wrap;align-items: center;}
.profile_form .user_card .justify-content-center h3 {font-weight: 700;font-size: 22px;line-height: 26px;text-align: center;color: #444444;padding: 20px 0;width: 100%;}
.yi_form_bottom .form-group.flex_center button {width: auto;padding: 15px 49px;margin-left: 16px;}
.yi_form_bottom .form-group.flex_center {justify-content: right;}
.profile_form .user_card p{font-size: 14px;font-weight: 600;color: #494949;}
.profile_form span.red_require {color: red;font-weight: 600;padding-left: 2px;}
.profile_form .upload_style input {border: 1px dashed #CCCCCC;border-radius: 6px;height: 87px;cursor: pointer;z-index: 99;position: relative;opacity: 0;}
.profile_form .upload_style{position: relative;margin: 0 10px 10px;}
.profile_form .upload_style .upload_div {border: 1px dashed #CCCCCC;border-radius: 6px;height: 87px;position: absolute;left: 0;right: 0;margin: auto;bottom: 0;top: 0;display: flex;align-items: center;justify-content: center;font-weight: 500;font-size: 13px;line-height: 127.4%;text-align: center;color: #494949;}

.profile_form input:focus,
.profile_form input{font-weight: 500;font-size: 13px;color: #494949}
.profile_form select:focus{border-color: #444444;outline: 0; box-shadow: 0 0 0 0.25rem rgb(68 68 68 / 5%);}
.profile_form select{font-weight: 500;font-size: 13px;color: #494949;-moz-appearance: none;appearance: none;-moz-appearance: none;background-image: url(../../../public/images/select_icon_right.svg); width: 100% !important;background-color: #FFFFFF;border: 1px solid #CCCCCC;box-sizing: border-box;border-radius: 6px !important;height: 41px;padding: 0 15px;background-repeat: no-repeat;background-position: 95%;}

/* Responsive Media Query */
@media (max-width: 650px) {
    
.profile_form .upload_style .upload_div,
.profile_form .upload_style input{height: 50px;}
.profile_form .modal-content{height: 600px;overflow-y: auto;overflow-x: hidden;}
body .row.flex_center {flex-wrap: wrap !important;}
.yi_form_bottom .form-group.flex_center button{padding: 15px 40px;}
.yi_form_bottom .form-group.flex_center {justify-content: left;margin-top: 12px;}


}