.contact_list_section h4{
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    color: #444444;
    margin-bottom: 30px;
    }
    body .contact_list_row{
        max-width: 1548px;
        margin: auto;
        /* padding: 0; */
    }
    
    body .contact_list_col {
        display: flex;
        flex-wrap: wrap;
    
    }
    
    .contact_list_col .contact_list {
        border: 1px solid #CCCCCC;
        border-radius: 10px;
        padding: 40px 30px;
        text-align: center;
        width: 23.33%;
        margin-right: 25px;
        margin-bottom: 25px;
    }
    
    .contact_list_col .contact_list .trade_wapper_contact ul {
        padding: 0;
        list-style: none;
        margin-bottom: 0;
    }
    
    .contact_list_col .contact_list .trade_wapper_contact ul li a {
        text-decoration: none;
        font-weight: 500;
        font-size: 15px;
        line-height: 155%;
    /* or 23px */
        color: #757575;
        text-align: left;
        width: 100%;
        display: flex;
        align-items: center;
        word-break: break-all;
    }
    
    .contact_list_col .contact_list .trade_wapper_contact ul li {
        text-align: left;
        margin-bottom: 20px;
    }
    .contact_list_col .contact_list:nth-child(4n){margin-right: 0;}
    .contact_list_col .contact_list .trade_wapper_contact ul li:last-child{margin-bottom: 0;}
    .contact_list_col .location_company_name h3{font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        text-align: center;
        color: #444444;}
    .contact_list_col .location_company_name p{font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        text-align: center;
        color: #757575;
        }
 .contact_list_col .location_company_name{
    margin-bottom: 30px;
 } 
 .contact_list_col .contact_list:hover{
    border: 1px solid #707070;
box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
 } 
 .contact_list_col .contact_list .trade_wapper_contact ul li a img {
    margin-right: 8px;
}

@media only screen and (max-width: 1500px){

    .contact_list_col .contact_list{
        width: 23%; 
    }

}

@media only screen and (max-width: 1280px){

    .contact_list_col .contact_list {
        width: 30%;
        padding: 40px 20px;
    }
    .contact_list_col .contact_list:nth-child(4n) {
        margin-right: 25px;
    }
    .contact_list_col .contact_list:nth-child(3n) {
        margin-right: 0;
    }
}

@media only screen and (max-width: 768px){
    .contact_list_col .contact_list {
        width: 100%;
        margin-right: 0;
    }
    .contact_list_col .contact_list:nth-child(4n){margin-right: 0;}
}