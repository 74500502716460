
.dashboard_bg h1{margin-bottom: 0;}
.dashboard_bg{background-image: url(../../../public/images/Mapbackgroundbw.jpg);background-repeat: no-repeat;background-size: cover;height: 482px;}
.dashboard_text{margin-top: -140px;}
.search_bar .input_field {
    border-radius: 10px;
    height: 65px;
    border: 0;
    padding: 10px 20px;
    width: 100%;
    max-width: 850px;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #8E8E8E;
    outline: none;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
   
}
.search_bar .input_field::placeholder{
    font-weight: 600;
    opacity: 1;
    color: #8E8E8E;
    font-size: 18px;
}
.submit_button{
    position: absolute;
    right: 20px;
    top: 24px;
    background-color: transparent;
    width: 20px;
    height: 20px;
    background-image: url(../../../public/images/find-icon.png);
    font-size: 0;
    border: 0;
}
.search_bar {
    box-shadow: 0px 4px 4px rgb(0 0 0 / 10%);
    border-radius: 10px;
    height: 65px;
    border: 0;
    width: 100%;
    max-width: 850px;
    margin: auto;
    position: relative;
}
input::-webkit-input-placeholder { /* Edge */
     font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #444444;
    font-family: 'Raleway', sans-serif;
  }
  
input:-ms-input-placeholder { /* Internet Explorer 10-11 */
     font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #444444;
    font-family: 'Raleway', sans-serif;
  }
  
 input::placeholder {
     font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #444444;
    font-family: 'Raleway', sans-serif;
  }
  .list_three_warp .col_4 {
    text-align: center;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 32px 30px;
} 
.list_three_warp .col_4 h3 {
    font-weight: 400;
    font-size: 50px;
    line-height: 47px;
    text-align: center;
    color: #444444;
}

.list_three_warp .col_4 p {
    font-weight: 600;
    font-size: 18px;
    line-height: 153.9%;
    text-align: center;
    color: #444444;
}
.list_logo_two .col_6.small-size-button a.yb_rot {
    padding: 25.5px 20px;
}
.list_logo_two .col_6 {
    background: #EEE6B8;
    border-radius: 10px;
    text-align: center;
}
.list_logo_two .col_6 a.yb_rot {
    display: inline-block;
    width: 100%;
    height: 100%;
    padding: 40px 20px;
    text-decoration: none;
}

.with_out_login_two_list.list_logo_two  .col_6 a.yb_rot h3 {
    font-weight: 400;
    font-size: 60px;
    line-height: 56px;
    text-align: center;
    color: #444444;
    margin-bottom: 0;
}

.list_logo_two .col_6 a.yb_rot h3 {
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    color: #444444;
    margin-bottom: 0;
}

.list_logo_two .myrow.row_flex{position: relative;max-width: 1480px;width: 100%;margin: auto;}
.with_out_login_two_list.list_logo_two .myrow.row_flex:before {
    top: 18px;
}

.list_logo_two .myrow.row_flex:before {
    background-image: url(../../../public/images/centerlogo.png);
    background-repeat: no-repeat;
    background-position: center;
    content: "";
    width: 128px;
    height: 126px;
    position: absolute;
    border: 9px solid #fff;
    border-radius: 100%;
    top: 30%;
}


/* new changes */

.search_bar form {
    height: 100%;
    display: inline-block;
    width: 100%;
}

.search_bar form .css-b62m3t-container {
    height: 100%;
}

.search_bar form .css-b62m3t-container .css-1w6q6cb-control, 
.search_bar form .css-b62m3t-container .css-1h4atcg-control {
    background-color: #fff;
    border: 0;
    border-radius: 10px;
    box-shadow: 0 4px 4px rgb(0 0 0 / 10%);
    color: #8e8e8e;
    font-size: 18px;
    font-weight: 600;
    height: 100%;
    line-height: 21px;
    max-width: 850px;
    padding: 10px 20px;
    width: 100%;
}
.search_bar form .css-b62m3t-container .css-1gtu0rj-indicatorContainer,
.search_bar form .css-b62m3t-container span.css-1okebmr-indicatorSeparator,
.search_bar form .css-b62m3t-container .css-tlfecz-indicatorContainer {
    display: none;
}

.search_bar{position: relative;}
.form_list {
    text-align: left;
    background-color: #ffff;
    border-radius: 5px;
    border: 1px solid #00000040;
    top: 65px;
    position: absolute;
    width: 100%;
    z-index: 1;
    padding: 10px;
    height: 150px;
    overflow: auto;
}
.form_list ul li{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 25px;
    color: #444444;
}
.form_list h2 {
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: #444444;
}
.form_list ul {
    list-style-type: none;
    padding-left: 10px;
}
.with_out_login_two_list p {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    color: #444444;
    padding-top: 0;
    margin-bottom: 0;
}
.single_text_section h2{
    color: #C7AD1B;
    font-size: 120px;
    font-weight: 400;
    line-height: 107px;
    margin-bottom: 0;
}

.single_text_section h3{
    font-size: 75px;
    font-weight: 400;
    line-height: 80px;
}
.single_text_section .welcome p{
    font-size: 24px;
    color: #444444;
    font-weight: 700;
}
.single_text_section .register_today p{
    font-weight: 400;
    font-size: 20px;
    color: #444444;
    line-height: 36px;
    padding-bottom: 30px;
    max-width: 1280px;
    margin: auto;
    width: 100%;
}

/* Responsive */

@media (max-width: 980px) {
    body .dashboard_bg{
        height: 265px;
    }
    body .with_out_login_two_list {
        margin-bottom: 0;
    }
    .single_text_section .register_today p {
        font-size: 16px;
        line-height: 31px;
        padding-bottom: 15px;
    }
    .single_text_section .welcome p{
        font-size: 18px;
    }
    .single_text_section h3 {
        font-size: 55px;
        font-weight: 400;
        line-height: 60px;
    }
    .single_text_section h2{
        font-size: 90px;
        line-height: 93px;
    }
    .dashboard_text {
        margin-top: -96px;
    }
    .list_logo_two .myrow.row_flex:before{position:relative;top: -53px;}
  
  }


  /* after login */

  .inner_page{
    height: 363px;
  }