.rightside .loaction_section .Adobe_Fine_Living_heading{display: flex;align-items: center;}
.rightside .loaction_section .Adobe_Fine_Living_heading .Adobe_Fine_Living_text{margin-left: 45px;}

.map_div {width: 100%;border-radius: 10px;height: 100%;overflow: hidden;margin-left: 10px;}
.map_div iframe {height: 984px;width: 100%;overflow: hidden;border-radius: 10px;}

div.box {text-align: center;width: 48px;border-radius: 3px;padding: 3px;background-image: url(../../../public/images/heart.svg);background-repeat: no-repeat;background-position: center;cursor: pointer;}
.box.entypo-thumbs-down{background-image: url(../../../public/images/heartred.svg);background-size: 31px;}
div.box {font-size: 0;text-decoration: none;color: #000;}
body .location_row {justify-content: space-between;align-items: self-start;}

.loaction_section .search_bar .input_field {box-sizing: border-box;border-radius: 5px;height: 42px;padding: 10px 20px;width: 100%;font-weight: 500;font-size: 18px;line-height: 21px;color: #444444;outline: none;border: 0;}

.loaction_section .submit_button{position: absolute;right: 8px;top: 13px;background-color: transparent;width: 20px;height: 20px;background-image: url(../../../public/images/find-icon.png);font-size: 0;border: 0;background-size: 16px;background-repeat: no-repeat;}
.loaction_section .search_bar {max-width: 210px;margin-top: 0;margin-bottom: 0; border: 1px solid #CCCCCC;box-sizing: border-box;border-radius: 5px;height: 44px;width: 100%;position: relative;}
body .find_row .col-sm-12{display: flex;align-items: center;justify-content: space-between;padding-bottom: 30px;border-bottom: 1px solid #D6D6D6;}

body .find_row .col-sm-12 h3 {width: 100%;max-width: 230px;margin-bottom: 4px;}
.location_row .col-sm-6{display: flex;flex-wrap: wrap;width: 48%;}

div#holder {display: flex;justify-content: end;}
.location_div .location_detail{display: flex;align-items: center;}
.location_div {width: 100%;}
.location_div .location_detail .location_icon {height: 54px;max-width: 54px;display: flex;align-items: center;justify-content: center;background-color: #ffffff;border-radius: 50%;width: 100%;}
.location_div .location_detail .location_info {margin-left: 29px;width: 100%;padding: 40px 0;border-bottom: 1px solid #D6D6D6;}
.location_div .location_detail .location_info h3{font-size: 13px;  font-weight: 500;line-height: 16.56px; color: #757575;margin-bottom: 9px;}
.location_div .location_detail .location_info address{ font-size: 17px;  font-weight: 700;line-height: 19.96px; color: #444444; margin-bottom: 0px}
.location_div .location_detail .location_info a{text-decoration: none; font-size: 17px;  font-weight: 700;line-height: 19.96px; color: #444444; margin-bottom: 0px;}
.location_div .Additional_Vendor_Contact{display: flex; align-items: center;flex-wrap: wrap;justify-content: space-between;max-width: 675px;}
.location_div .Vendor_Name , .Vendor_Number{display: flex;align-items: center;width: 100%;max-width: 300px;}
.location_div .Additional_Vendor_Contact .location_info {margin-left: 29px;width: 100%;padding: 40px 0;border-bottom: 1px solid #D6D6D6;}
.location_div .Additional_Vendor_Contact .location_icon {height: 54px;max-width: 54px;display: flex;align-items: center;justify-content: center;background-color: #ffffff;border-radius: 50%;width: 100%;}
.location_div .social_icon_div{display: flex;padding-top: 49px; padding-bottom: 59px;width: 100%; flex-wrap: wrap;padding-bottom: 20px;}
.location_div .social_icon_div .social_icon {margin-right: 17px;display: flex;align-items: center;justify-content: center;background-color: #ffffff;border-radius: 144px;padding-top: 16px;padding-bottom: 16px;max-width: 233px;margin-bottom: 20px;width: 100%;}
.location_div .social_icon_div .social_icon a{text-decoration: none; font-size: 15px;padding-left: 10px;  font-weight: 700;line-height: 17.61px; color: #444444; margin-bottom: 0px;}
.location_div .social_icon_div .social_icon:last-child {margin-right: 0;}
.location_div .Additional_Vendor_Contact h3{font-size: 13px;  font-weight: 500;line-height: 16.56px; color: #757575;margin-bottom: 9px;}
.location_div .Additional_Vendor_Contact p{ font-size: 17px;  font-weight: 700;line-height: 19.96px; color: #444444; margin-bottom: 0px;}
.location_div .Additional_Vendor_Contact a{text-decoration: none; font-size: 17px;  font-weight: 700;line-height: 19.96px; color: #444444; margin-bottom: 0px;}
.location_div .Additional_Vendor_Contact_div h2{font-style: normal;font-weight: 700;font-size: 18px;line-height: 21px;color: #444444;margin-top: 20px;}
.border-0 {
    border:0;
  }
/* --------- Screen Size 1450px css --------- */
@media (max-width: 1450px) {
.location_row .col-sm-6{margin-right: 0;}
}

/* --------- Screen Size 1280px css --------- */

@media (max-width: 1280px) {
body .map_div iframe {height: 626px;}
}

/* --------- Screen Size 1024px css --------- */

@media (max-width: 1024px) {
body .map_div iframe {height: 384px;}
}

/* --------- Screen Size 980px css --------- */

@media (max-width: 980px) {
.location_row .col-sm-6 {display: flex;flex-wrap: wrap;width: 100%;}
body .location_row{margin-bottom: 30px;}
.location_company_name {width: 100%;}
}

/* --------- Screen Size 650px css --------- */

@media (max-width: 650px) {
.rightside .loaction_section .Adobe_Fine_Living_heading .Adobe_Fine_Living_text {
    margin-left: 0;
    margin-top: 20px;
}
body .find_row .col-sm-12{flex-wrap: wrap;}
.rightside .loaction_section .Adobe_Fine_Living_heading{margin-bottom: 25px;flex-wrap: wrap;}
body .loaction_section {margin-right: 0;}
.location_div .social_icon_div .social_icon{width: 100%; margin-right: 0px;}
.location_div .Additional_Vendor_Contact{width: 100%;}
.location_div .Vendor_Name, .Vendor_Number{width: 100%;max-width: 100%; margin-right: 0;}
}
.dropdown-toggle::after{
    display: none!important;
}














/* global css */
.left_side{
    float: left;
    /* padding-top: 30px; */
    padding-left: 10px;
}

.left_side_afterLogin{
    float: left;
    padding-top: 30px;
    padding-left: 10px;
}
.mobile_narbar span{
    font-size: 30px;
    color: #444444;
}

@media (max-width: 980px){
.sidebar .mobile_narbar {
    display: inline-block;
    position: relative;
    top: 27px;
    left: 15px;
}
}
.active-s{
    left: 0;
}

.Adobe_Fine_Living_img {
    width: 100%;
    max-width: 120px;
    overflow: hidden;
}

.Adobe_Fine_Living_img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}







  