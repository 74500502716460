/* Googlefont Poppins CDN Link */
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Raleway', sans-serif !important;
}


@font-face {
  font-family: 'Raleway', sans-serif;
  src: url('../fonts/Raleway-Bold.woff2') format('woff2'),
      url('../fonts/Raleway-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway', sans-serif;
  src: url('../fonts/Raleway-ExtraBold.woff2') format('woff2'),
      url('../fonts/Raleway-ExtraBold.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway', sans-serif;
  src: url('../fonts/Raleway-Light.woff2') format('woff2'),
      url('../fonts/Raleway-Light.woff') format('woff');
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway', sans-serif;
  src: url('../fonts/Raleway-Medium.woff2') format('woff2'),
      url('../fonts/Raleway-Medium.woff') format('woff');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway', sans-serif;
  src: url('../fonts/Raleway-Regular.woff2') format('woff2'),
      url('../fonts/Raleway-Regular.woff') format('woff');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway', sans-serif;
  src: url('../fonts/Raleway-SemiBold.woff2') format('woff2'),
      url('../fonts/Raleway-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avery';
  src: url('../fonts/Avery.woff2') format('woff2'),
      url('../fonts/Avery.woff') format('woff');
  font-display: swap;
}


.avery{font-family: 'Avery' !important;}

:root {
--gary-dark:#444444;
--dark-black: #373737;

}
body h1{font-size: 80px;color: var(--dark-black);font-weight: 400;}
.graycolor{color: var(--gary-dark);}
.text-center{text-align: center;}

.mt-1{margin-top: 10px}
.mb-1{margin-bottom: 10px}
.ml-1{margin-left: 10px}
.mr-1{margin-right: 10px}

.mt-2{margin-top: 20px}
.mb-2{margin-bottom: 20px}
.ml-2{margin-left: 20px}
.mr-2{margin-right: 20px}

.mt-3{margin-top: 30px}
.mb-3{margin-bottom: 30px}
.ml-3{margin-left: 30px}
.mr-3{margin-right: 30px}

.mt-4{margin-top: 40px}
.mb-4{margin-bottom: 40px}
.ml-4{margin-left: 40px}
.mr-4{margin-right: 40px}

.mt-5{margin-top: 50px}
.mb-5{margin-bottom: 50px}
.ml-5{margin-left: 50px}
.mr-5{margin-right: 50px}

.mt-6{margin-top: 60px}
.mb-6{margin-bottom: 60px}
.ml-6{margin-left: 60px}
.mr-6{margin-right: 60px}

.mt-8{margin-top: 80px}
.mb-8{margin-bottom: 80px}
.ml-8{margin-left: 80px}
.mr-8{margin-right: 80px}

.mr-150{margin-top: 150px}

.pt-1{padding-top: 10px}
.pb-1{padding-bottom: 10px}
.pl-1{padding-left: 10px}
.pr-1{padding-right: 10px}

.pt-2{padding-top: 20px}
.pb-2{padding-bottom: 20px}
.pl-2{padding-left: 20px}
.pr-2{padding-right: 20px}

.pt-3{padding-top: 30px}
.pb-3{padding-bottom: 30px}
.pl-3{padding-left: 30px}
.pr-3{padding-right: 30px}

.pt-4{padding-top: 40px}
.pb-4{padding-bottom: 40px}
.pl-4{padding-left: 40px}
.pr-4{padding-right: 40px}

.pt-5{padding-top: 50px}
.pb-5{padding-bottom: 50px}
.pl-5{padding-left: 50px}
.pr-5{padding-right: 50px}

body .search_bar.side_search {
  margin-bottom: 30px;
  padding-bottom: 44px;
}
.search_bar.side_search .input_field {
  box-sizing: border-box;
  border-radius: 5px;
  height: 42px;
  padding: 10px 20px;
  width: 100%;
  max-width: 850px;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #444444;
  outline: none;
  border: 0;
}

.side_search .submit_button{
  position: absolute;
  right: 8px;
  top: 13px;
  background-color: transparent;
  width: 20px;
  height: 20px;
  background-image: url(../../../public/images/find-icon.png);
  font-size: 0;
  border: 0;
  background-size: 16px;
  background-repeat: no-repeat;
}
.search_bar.side_search {border: 1px solid #CCCCCC;box-sizing: border-box;border-radius: 5px;height: 42px;width: 100%;max-width: 850px;margin: auto;position: relative;}
input::-webkit-input-placeholder { /* Edge */
   font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #868686;
  font-family: 'Raleway', sans-serif;
}
span.red_dot {
  color: red;
  padding-left: 2px;
}

input:-ms-input-placeholder { /* Internet Explorer 10-11 */
   font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #868686;
  font-family: 'Raleway', sans-serif;
}

input::placeholder {
   font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #868686;
  font-family: 'Raleway', sans-serif;
}

.myrow {
  padding: 0 20px;
}
body .row{--bs-gutter-x: 0rem; --bs-gutter-y: 0;}
.inner_row{padding: 0 35px;}
body h3{
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  color: #444444;
}

.loggend_header{display: inline-block;width: 100%;}
.loggend_header a {
    width: 38px;
    height: 38px;
    color: #ffff;
    border-radius: 100%;
    font-size: 0;
    display: inline-block;
    background-position: center;
    background-repeat: no-repeat;
    background-color: rgba(68, 68, 68, 0.7);
}
.loggend_header a.yi_n:hover{
  color: #fff;
}
.profile_text p a {
  display: block;
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  color: rgba(68, 68, 68, 0.5);
}
.profile_text a.profile_word {
  background: #C7AD1B;
  color: #fff;
  text-decoration: none;
  font-size: 17px;
  width: 33px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  font-weight: 700;
  margin-bottom: 15px;
}
.profile_text {
  margin-bottom: 26px;
}
.profile_text p a {
  display: block;
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  color: rgba(68, 68, 68, 0.5);
}
.profile_text p{
  margin-bottom: 0;
}

body .profile_text h3{
  font-size: 20px;
}
.loggend_header a.yi_n{
  /* background-image: url(../../../public/images/n-icon.svg); */
  font-size: 17px;
  text-decoration: none;
  font-weight: 900;
  display: flex;
  align-items: center;
  justify-content: center;
  }
.loggend_header a.yi_backpage{background-image: url(../../../public/images/pagebackicon.png);background-position: 12px;margin-left: 6px;}
.loggend_header .right_btn {display: flex; float: right;padding-bottom: 30px;padding-top: 30px;}
.row_flex{display: flex;flex-wrap: wrap;width: 100%;justify-content: center;padding: 0 20px;}
.col_4{float: left;width: 31.33%;margin-right: 22px;}
.col_6:nth-child(2n),
.col_4:nth-child(3n){margin-right: 0;}
.col_6{float: left;width: 47.33%;margin-right: 33px;margin-bottom: 35px;}
a.same_btn:hover{color: #444444;}
body a.big_btn {
  font-size: 22px;
  line-height: 26px;
  width: 100%;
  height: auto;
  padding: 35px 20px;
}
.btn_note,
a.same_btn {
  width: 209px;
  height: 47px;
  background: #EEE6B8;
  border-radius: 8.28358px;
  font-style: normal;
  font-weight: 700;
  font-size: 14.9104px;
  line-height: 18px;
  text-align: center;
  color: #444444;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  border: 0;
}

.withoutlogin_btn_div a.same_btn{
  margin: 0px;
}

.btn:focus,
.btn:hover{
  background: #EEE6B8;
  opacity: 0.6;
  color: #444444;
}

.menu_srcoll{max-height: 75vh;overflow-y: auto;margin-bottom: 20px;overflow-x: hidden;padding-left: 0;}
 .menu_srcoll::-webkit-scrollbar {
    background-color:#fff;
    width:5px
}

.menu_srcoll::-webkit-scrollbar-track {
    background-color:#fff;
    position: relative;
    z-index: 99;
}

 .menu_srcoll::-webkit-scrollbar-track:hover {
  background-color:#fff;
    position: relative;
    z-index: 99;
}

 
 .menu_srcoll::-webkit-scrollbar-thumb {
    background-color:#DADADA;
    border-radius:16px;
    border:2px solid #DADADA;
    position: relative;
    z-index: 99;
}
 
 .menu_srcoll::-webkit-scrollbar-thumb:hover {
  background-color:#DADADA;
    border:4px solid #DADADA;
    position: relative;
    z-index: 9;
}
header.header {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9;
}
 .menu_srcoll::-webkit-scrollbar-button {
    display:none;
    z-index: 99;
}
.login_signup{padding-top: 30px;padding-left: 20px;padding-right: 30px;}
.login_signup .right_btn {
  float: right;
  display: flex;
}

span.red.error_message {
  font-size: 14px;
  display: block;
  margin-top: 10px;
}
span.red {
  color: red;
}

button.same_btn:hover,
button.same_btn:focus{box-shadow: inherit;color: #444444;}
button.same_btn,
.modal-body button.btn{
  background: #EEE6B8;
  border-radius: 8.28358px;
  width: 100%;
  font-weight: 700;
  font-size: 14.9104px;
  line-height: 18px;
  text-align: center;
  color: #444444;
  padding: 15px 10px;
  margin-top: 10px;
  opacity: 1;
  }
.modal-body .links{
font-weight: 400;
font-size: 13px;
line-height: 127.4%;
text-align: center;
color: #494949;
}
.form-group  input[type="checkbox"] {
  width: auto !important;
  height: auto;
}
.row.hiding_form {
  opacity: 0.4;
}
.flex_center{
  display: flex;
  align-items: center;
}
body .row.flex_center {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}
body .form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #444444;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(68 68 68 / 5%);
}
.form-group.checkbox_style label {
  padding-left: 8px;
  padding-bottom: 0;
}
.checkbox_style {display: flex; align-items: center;}

.form-group label,
.input-group label{
  float: left;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 127.4%;
  color: #494949;
  padding-bottom: 6px;
}
.form-group{padding: 0 10px;}
body .form-group textarea{resize: none;height: 80px;}
.form-group textarea,
.form-group input,
.input-group input{
  width: 100% !important;
  background: #FFFFFF;
  border: 1px solid #CCCCCC;
  box-sizing: border-box;
  border-radius: 6px !important;
  height: 41px;
  padding: 0 15px;
}
.input-group{width: 100%;}
.user_card .justify-content-center form {
  width: 100%;
}
.modal.fade .modal-dialog .modal-body {
  padding: 40px 45px;
}


.login_signup .right_btn a.same_btn:hover{background-color: #EEE6B8;color: #444444;}
.login_signup .right_btn a.same_btn {
  width: 135px;
  background-color: #fff;
  height: 100%;
  padding: 14px 0 13px;
  font-size: 13px;
}
.login_signup .right_btn a.same_btn.login{margin-left: 20px !important;}
.sidebar{
  position: fixed;
  height: 100%;
  width: 100%;
  transition: all 0.5s ease;
  background: #FFFFFF;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  padding: 0px 20px;
  max-width: 270px;
  z-index: 99;
}
.sidebar.active{
  width: 60px;
}
.sidebar .mobile_narbar{display: none;}
.sidebar .logo-details{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}
.sidebar .logo-details i{
  font-size: 28px;
  font-weight: 500;
  color: #fff;
  min-width: 60px;
  text-align: center
}
.sidebar .logo-details .logo_name a,
.sidebar .logo-details .logo_name{font-style: normal;font-weight: 600;font-size: 38.5669px;line-height: 96.5%;
text-decoration: none;
/* or 37px */text-align: center;color: #444444;text-shadow: 0px 10.5489px 41.0234px rgba(0, 0, 0, 0.15);}
.sidebar .nav-links{
  margin-top: 0px;
  padding: 15px 0px;
}
li.sub_menu_main.active > ul {
  display: block;
}

.sidebar .nav-links li{
  position: relative;
  list-style: none;
  height: 50px;
}

.sidebar .nav-links li img {
  width: 26px;
}

.sidebar .nav-links li a{
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  color: #444444;
}
.sidebar .nav-links li a.active{
  background: #fdfdfd;
}
.sidebar .nav-links li a.active i,
.sidebar .nav-links li a:hover i,
.sidebar .nav-links li a:hover span,
.sidebar .nav-links li a.active span{color: #44444491;}
.sidebar .nav-links li a:hover{
  background: #fefefe;
}
.sidebar .nav-links li span {
  margin-left: 16px;
}
.main {
  display: flex;
}
body b,
body strong{
  font-weight: 700;
}
.cat_seach h4{
  margin-top: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #444444;
  margin-bottom: 0;
  }
  .mobile_narbar a:hover{color: #444444;opacity: 0.7;}
  .active-s  .mobile_narbar{transform: rotate(90deg);}
  .mobile_narbar {
      transform: rotate(45deg);
      width: auto;
      display: block;
      float: left;
  }
  .mobile_menu.show{display: block;}
.mobile_narbar a{font-size: 30px;color: #444444;}
nav {
  width: 100%;
  display: inline-block;
}
.sub_menu {
  position: fixed;
  left: 270px;
  top: 0;
  background: #FFFFFF;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  width: 270px;
  height: 100vh;
  padding: 20px 20px;
  display: none;
}
.sub_menu .sub_menu {
  left: 540px;
}
.sub_menu ul.menu_srcoll {
  max-height: 90vh;
}
.sub_menu .sub_menu .sub_menu {
  left: 810px;
}
.sidebar .nav{display: none;}
.sidebar .nav-links li i{
  min-width: 60px;
  text-align: center;
  font-size: 18px;
  color: #444444;
}
.arvay{font-family: Arial, Helvetica, sans-serif;}
.sidebar .nav-links li a .links_name{font-style: normal;font-weight: 500;font-size: 14px;line-height: 16px;
/* identical to box height */color: #444444;}
.sidebar .nav-links .log_out{
  position: absolute;
  bottom: 0;
  width: 100%;
}
footer ul.media_link li.facebook:hover a {background-image: url(../../../public/images/facebook_new_white.png);}
footer ul.media_link li.link:hover a {background-image: url(../../../public/images/linkedin_new_white.png);}
footer ul.media_link li.insta:hover a {background-image: url(../../../public/images/insta_new_white.png);}
footer ul.media_link li:hover{background: rgba(68, 68, 68, 0.7);}
footer ul.media_link li.facebook a {background-image: url(../../../public/images/facebook_dark.png);}
footer ul.media_link li.link a {background-image: url(../../../public/images/linkedin_dark.png);}
footer ul.media_link li.insta a {background-image: url(../../../public/images/insta_dark.png);}
footer .myrow{align-items: center;}
ul.footer_menu li:last-child::after{
  display: none;
}
ul.footer_menu li:after {
  content: "";
  width: 2px;
  height: 14px;
  background-color: rgba(68, 68, 68, 0.7);
  margin: 0 11px;
  position: absolute;
  right: -25px;
  bottom: 0;
  top: 4px;
}
ul.footer_menu {
  display: flex;
  padding: 0;
  margin-bottom: 0;
}

ul.footer_menu li {
  list-style: none;
  margin-right: 24px;
  position: relative;
}
ul.footer_menu li:last-child{
  margin-right: 0;
}
ul.footer_menu li a:hover{
  opacity: 0.7;
}
ul.footer_menu li a {
  font-weight: 700;
  font-size: 16px;
  color: rgba(68, 68, 68, 0.7);
  text-decoration: none;
}


ul.media_link li:last-child::after{
  display: none;
}
ul.media_link li:after {
  width: 2px;
  height: 14px;
  background-color: rgba(68, 68, 68, 0.7);
  margin: 0 11px;
  position: absolute;
  right: -25px;
  bottom: 0;
  top: 4px;
}
ul.media_link {
  display: flex;
  padding: 0;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 0;
}

ul.media_link li {
  list-style: none;
  margin-right: 14px;
  position: relative;
  max-width: 42px;
  height: 42px;
  width: 100%;
  border-radius: 100%;
  box-shadow: 0px 2.18898px 5.10763px rgba(0, 0, 0, 0.05);
  overflow: hidden;
}
ul.media_link li a {
  font-weight: 700;
  font-size: 0;
  color: rgba(68, 68, 68, 0.7);
  text-decoration: none;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
}

a.backhome:hover{color: #fff;}
a.backhome {
  background-color: rgba(68, 68, 68, 0.7);
  border-radius: 19px;
  color: #fff;
  text-decoration: none;
  font-weight: 700;
  font-size: 13px;
  max-width: 152px;
  display: block;
  width: 100%;
  padding: 11px 13px 11px 46px;
  position: relative;
  z-index: 9;
}
a.backhome span {
  font-size: 0;
  width: 30px;
  height: 30px;
  background-color: #F6F6F6;
  display: block;
  border-radius: 100%;
  position: absolute;
  left: 7px;
  top: 5px;
  background-image: url(../../../public/images/backhome.svg);
  background-position: 8px 8px;
  background-repeat: no-repeat;
  line-height: normal;
}



footer.footer{min-height: auto;background: #fff;border-top: 1px solid #CCCCCC;}
footer,
.rightside{
  min-height: 100vh;
  transition: all 0.5s ease;
  left: 270px;
  background: linear-gradient(0deg, rgba(224, 224, 224, 0.7) 0%, rgba(255, 255, 255, 0) 100%);
  position: relative;
  width: calc(100% - 270px);
}
.sidebar.active ~ .rightside{
  width: calc(100% - 60px);
  left: 60px;
}
.rightside nav{
  display: flex;
  justify-content: space-between;
  height: 80px;
  background: #fff;
  display: flex;
  align-items: center;
  position: fixed;
  width: calc(100% - 240px);
  left: 240px;
  z-index: 100;
  padding: 0 20px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease;
}
.sidebar.active ~ .rightside nav{
  left: 60px;
  width: calc(100% - 60px);
}
.rightside nav .sidebar-button{
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 500;
}
nav .sidebar-button i{
  font-size: 35px;
  margin-right: 10px;
}
.rightside nav .search-box{
  position: relative;
  height: 50px;
  max-width: 550px;
  width: 100%;
  margin: 0 20px;
}
nav .search-box input{
  height: 100%;
  width: 100%;
  outline: none;
  background: #F5F6FA;
  border: 2px solid #EFEEF1;
  border-radius: 6px;
  font-size: 18px;
  padding: 0 15px;
}
nav .search-box .bx-search{
  position: absolute;
  height: 40px;
  width: 40px;
  background: #2697FF;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 4px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  font-size: 22px;
  transition: all 0.4 ease;
}
.rightside nav .profile-details{
  display: flex;
  align-items: center;
  background: #F5F6FA;
  border: 2px solid #EFEEF1;
  border-radius: 6px;
  height: 50px;
  min-width: 190px;
  padding: 0 15px 0 2px;
}
nav .profile-details img{
  height: 40px;
  width: 40px;
  border-radius: 6px;
  object-fit: cover;
}
nav .profile-details .admin_name{
  font-size: 15px;
  font-weight: 500;
  color: #333;
  margin: 0 10px;
  white-space: nowrap;
}
nav .profile-details i{
  font-size: 25px;
  color: #333;
}
.rightside .home-content{
  position: relative;
  padding-top: 104px;
}
.home-content .overview-boxes{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 20px;
  margin-bottom: 26px;
}
.overview-boxes .box{
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% / 4 - 15px);
  background: #fff;
  padding: 15px 14px;
  border-radius: 12px;
  box-shadow: 0 5px 10px rgba(0,0,0,0.1);
}
.overview-boxes .box-topic{
  font-size: 20px;
  font-weight: 500;
}
.home-content .box .number{
  display: inline-block;
  font-size: 35px;
  margin-top: -6px;
  font-weight: 500;
}
.home-content .box .indicator{
  display: flex;
  align-items: center;
}
.home-content .box .indicator i{
  height: 20px;
  width: 20px;
  background: #8FDACB;
  line-height: 20px;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  font-size: 20px;
  margin-right: 5px;
}
.box .indicator i.down{
  background: #e87d88;
}
.home-content .box .indicator .text{
  font-size: 12px;
}
.home-content .box .cart{
  display: inline-block;
  font-size: 32px;
  height: 50px;
  width: 50px;
  background: #cce5ff;
  line-height: 50px;
  text-align: center;
  color: #66b0ff;
  border-radius: 12px;
  margin: -15px 0 0 6px;
}
.home-content .box .cart.two{
   color: #2BD47D;
   background: #C0F2D8;
 }
.home-content .box .cart.three{
   color: #ffc233;
   background: #ffe8b3;
 }
.home-content .box .cart.four{
   color: #e05260;
   background: #f7d4d7;
 }
.home-content .total-order{
  font-size: 20px;
  font-weight: 500;
}
.home-content .sales-boxes{
  display: flex;
  justify-content: space-between;
  /* padding: 0 20px; */
}

/* left box */
.home-content .sales-boxes .recent-sales{
  width: 65%;
  background: #fff;
  padding: 20px 30px;
  margin: 0 20px;
  border-radius: 12px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}
.home-content .sales-boxes .sales-details{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sales-boxes .box .title{
  font-size: 24px;
  font-weight: 500;
  /* margin-bottom: 10px; */
}
.sales-boxes .sales-details li.topic{
  font-size: 20px;
  font-weight: 500;
}
.sales-boxes .sales-details li{
  list-style: none;
  margin: 8px 0;
}
.sales-boxes .sales-details li a{
  font-size: 18px;
  color: #333;
  font-size: 400;
  text-decoration: none;
}
.sales-boxes .box .button{
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.sales-boxes .box .button a{
  color: #fff;
  background: #0A2558;
  padding: 4px 12px;
  font-size: 15px;
  font-weight: 400;
  border-radius: 4px;
  text-decoration: none;
  transition: all 0.3s ease;
}
.sales-boxes .box .button a:hover{
  background:  #0d3073;
}

/* Right box */
.home-content .sales-boxes .top-sales{
  width: 35%;
  background: #fff;
  padding: 20px 30px;
  margin: 0 20px 0 0;
  border-radius: 12px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}
.sales-boxes .top-sales li{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}
.sales-boxes .top-sales li a img{
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 12px;
  margin-right: 10px;
  background: #333;
}
.sales-boxes .top-sales li a{
  display: flex;
  align-items: center;
  text-decoration: none;
}
.sales-boxes .top-sales li .product,
.price{
  font-size: 17px;
  font-weight: 400;
  color: #333;
}
/* Responsive Media Query */

@media (max-width: 1450px){

  .col_4.footer_logo {
    width: 100%;
    margin: 0;
    max-width: 106px;
    padding: 20px 0px;
}

.col_4.footer_menu_div {
    width: 51%;
}

.col_4.social_div {
    width: 31%;
}
}
@media (max-width: 1240px) {
  .sub_menu .sub_menu .sub_menu {
    left: 720px;
}
  .sub_menu .sub_menu {
    left: 480px;
}
  .sub_menu{left: 240px;}
  .sub_menu,
  .sidebar{
  max-width: 240px;
  }
  .sidebar.active{
    width: 220px;
  }
  footer,
  .rightside{
  width: calc(100% - 240px);
  left: 240px;
  }
  .sidebar.active ~ .rightside{
    /* width: calc(100% - 220px); */
    overflow: hidden;
    left: 220px;
  }
  .rightside nav{
    width: calc(100% - 60px);
    left: 60px;
  }
  .sidebar.active ~ .rightside nav{
    width: calc(100% - 220px);
    left: 220px;
  }
}

@media (max-width: 1024px) {

  body h1{font-size: 70px;}

}
@media (max-width: 980px) {
  .left_btn {
    margin-left: 26px;
}
  footer.footer{padding: 30px 0 !important;}
  ul.footer_menu,
  ul.media_link{justify-content: center;}
  .col_4.social_div {
    width: 100%;
    margin-bottom: 0;
}
  .col_4.footer_menu_div {
    width: 100%;
    margin-bottom: 0;
}
  .dropdown{display: none;}
  footer,
  .rightside {
    width: 100%;
    left: 0;
  }
  .sub_menu, .sidebar {
    max-width: 450px;
    left: -490px;
}
  .sub_menu .sub_menu,
  .sub_menu {
    position: relative;
    left: 0;
    z-index: 9999;
    height: auto;
    top: -6px;
    padding-top: 10px;
}
  .hide_menu .rightside {
    width: calc(100% - 0px);
    left: 0px;
}
  .hide_menu .sidebar { max-width: 60px;height: auto;top: 21px;}
  .hide_menu .sidebar .mobile_menu{display: none;}
  .sidebar .mobile_narbar{ display: inline-block; position: relative;top: 17px;left: 15px;} /* new changes */


  .mobile_menu.dropdown{display: none;}
  body h1 {
    font-size: 48px;
}
  .col_4,
  .col_6{width: 100%;margin-right: 0;margin-bottom: 30px;}

}


@media (max-width: 700px) {
  body .hide_menu .sidebar {
    max-width: 52px;
    height: 40px;
    top: 29px;
}
  body .mobile_narbar a {font-size: 25px; }
  body .login_signup .right_btn a.same_btn {width: 108px;}
  body .login_signup {
    padding-top: 25px;
    padding-left: 20px;
    padding-right: 18px;
}
  nav .sidebar-button .dashboard,
  nav .profile-details .admin_name,
  nav .profile-details i{
    display: none;
  }
  .rightside nav .profile-details{
    height: 50px;
    min-width: 40px;
  }
  .home-content .sales-boxes .sales-details{
    width: 560px;
  }
}
@media (max-width: 550px) {
  body h1 {
    font-size: 35px;
}
  ul.footer_menu{flex-wrap: wrap;}
  ul.footer_menu li{width: 100%;margin-right: 0;margin-bottom: 10px;}
  ul.footer_menu li:after{display: none;}
  .modal.fade .modal-dialog .modal-body {
    padding: 40px 30px;
  }
  .overview-boxes .box{
    width: 100%;
    margin-bottom: 15px;
  }
  .sidebar.active ~ .rightside nav .profile-details{
    display: none;
  }
}



/* ----------- new changes ---------- */


.left_side{display: none;}

@media (max-width: 400px) {
  body a.big_btn {
    font-size: 16px;
    padding: 25px 20px;
}
.sidebar .logo-details .logo_name{font-size: 28px;padding-top: 9px;}
.sidebar .logo-details{padding-bottom: 0px;}
}
.mobile_narbar span{font-size: 30px;color: #444444;}

@media (max-width: 980px){

  body span.cross{margin-right: 11px;margin-top: 10px;}
  li.sub_menu_main.active:before{transform: rotate(270deg);}
  li.sub_menu_main:before {
    background-image: url(../../../public/images/select_icon_right.svg);
    background-repeat: no-repeat;
    background-position: 95%;
    content: "";
    width: 7px;
    height: 5px;
    position: absolute;
    right: 20px;
    bottom: 0;
    top: 42%;
    transform: rotate(90deg);
  }
.sidebar .mobile_narbar {display: inline-block;position: relative;top: 27px;left: 15px;}
.left_side{display: block;float: left;padding-top: 30px;padding-left: 10px;}
body .login_signup .left_side{padding-top: 0;}


}

.active-s{left: 0;}

/* new changes */

.dropdown-toggle::after{
    display: none!important;
}

/* cross icon css */
.active > .sub_menu{display: block;}
body .sidebar span.cross img {width: 15px;opacity: 0.2;}

span.cross {display: flex;justify-content: end;align-items: center;margin-right: 20px;margin-bottom: 12px;}


.border-0 {
  border:0;
}



.coming_soon{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
}
.coming_soon_box{
  border-radius: 8.28358px;
  width: 50vw;
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.coming_soon h2{
font-style: normal;
  font-weight: 600;
  font-size: 70.5669px;
  line-height: 96.5%;
  text-align: center;
  color: #444444;
}


.loader-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: transparent;
  display: grid;
  place-items: center;
}


.loader {
  border: 16px solid #e3e3e3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 140px;
  height: 140px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.location_company_name p a{word-break: break-all;}`

.tooltip-inner{
  background-color: #444444;
  border-color: #444444;
}

 /* after login */

 .inner_page{
  height: 363px;
  background-image: url(../../../public/images/Mapbackgroundbw_inner.jpg);
}
/* .sidebar .nav-links li a.what’s_new img {
  width: 36px;
} */

.breadcrumb_col a:hover{
  color: #444444;
}
.breadcrumb_col a {
  color: rgba(68, 68, 68, 0.6);
  font-weight: 700;
  font-size: 13px;
  text-decoration: none;
}

.breadcrumb_col a.disabled {
  color: #444444;
}

.breadcrumb_col span.spt {
  color: rgba(68, 68, 68, 0.6);
  font-weight: 700;
  font-size: 13px;
  margin: 0 5px;
}

.breadcrumb_section {margin-bottom: 40px;}

ul li.category_single {
  cursor: pointer;
}

.popup-box::before{
  content: "▼";
  position: absolute;
  top: 54px;
  color: #ffff;
  left: 5px;
}