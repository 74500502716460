.arizona_map_post .arizona_map_row {display: flex;flex-wrap: wrap;margin-top: 15px;}
.arizona_map_post .arizona_map_row .arizona_map_list {float: left;width: 23%;background: #FFFFFF;border: 1px solid #CCCCCC;box-sizing: border-box;border-radius: 10px;margin-right: 30px;margin-bottom: 30px;position: relative;}
.arizona_map_post .arizona_map_row .arizona_map_list:nth-child(4n) {margin-right: 0;}
.arizona_map_post .arizona_map_row .arizona_map_list .map_image {width: 100%;height: 191px;overflow: hidden;border-radius: 10px 10px 0px 0px;}
.arizona_map_post .arizona_map_row .arizona_map_list .map_image img {width: 100%;height: 100%;object-fit: cover;}
/* .arizona_map_list .map_image iframe{width: 100%; height: 100%;} */
.arizona_map_post .arizona_map_row .arizona_map_list .map_text {padding: 20px 30px; background-color: #D1D1D1;}
.arizona_map_row .arizona_map_list .map_text h4{text-align: center; font-weight: 700; font-size: 18px; color: #444444;}


.iframe{
    border:0;
}
/* --------- Screen size 1450px css --------- */

@media (max-width: 1450px) {
.arizona_map_post .arizona_map_row .arizona_map_list{margin-right: 28px;margin-bottom: 28px;}
}

/* --------- Screen size 1280px css --------- */

@media (max-width: 1400px) {
.arizona_map_post .arizona_map_row .arizona_map_list:nth-child(4n){margin-right: 28px;}
}

/* --------- Screen size 1280px css --------- */

@media (max-width: 1280px) {
.arizona_map_post .arizona_map_row .arizona_map_list:nth-child(4n){margin-right: 28px;}
.arizona_map_post .arizona_map_row .arizona_map_list{width: 29%;}
}

/* --------- Screen size 980px css --------- */

@media (max-width: 980px) {
.arizona_map_post .arizona_map_row .arizona_map_list:nth-child(4n){margin-right: 0;}
.arizona_map_post .arizona_map_row .arizona_map_list{width: 100%;margin-right: 0;} 
}

/* --------- Screen size 650px css --------- */

@media (max-width: 650px) {
.chose_box .form-group .custom_radio .imgcover{max-width: 100%;}
.chose_box .form-group .custom_radio{width: 100%;margin-right: 0;}
.project_form .modal-dialog .modal-body {padding: 55px 35px;}   
}