
.login_form .modal.fade .modal-dialog {
    max-width: 468px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.login_form .user_card .justify-content-center{flex-wrap: wrap;}
.login_form .user_card .justify-content-center h3 {
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    color: #444444;
    padding: 20px 0;
}
