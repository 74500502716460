/* The container */
.checkbox_style_div .container-checkbox {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    font-weight: 700;
font-size: 17.2526px;
line-height: 20px;

color: #444444;user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox_style_div .container-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom checkbox */
.checkbox_style_div .container-checkbox .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #BBBBC1;
}

/* On mouse-over, add a grey background color */
.checkbox_style_div .container-checkbox:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox_style_div .container-checkbox input:checked ~ .checkmark {
    background-color: #BBBBC1;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkbox_style_div .container-checkbox .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox_style_div .container-checkbox input:checked ~ .checkmark:after {
    display: block;
}
.checkbox_style_div .container-checkbox.am input ~ .checkmark {
    background-color: #EEE6B8;
}
.checkbox_style_div .container-checkbox.registered input ~ .checkmark {
    background-color: #E3E3E3;
}

/* Style the checkmark/indicator */
.checkbox_style_div .container-checkbox .checkmark:after {
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid #C61A1A;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.checkbox_style_div .form-group {
    padding: 0 14px;
}

.event_row_checker .col-sm-12 {
    display: flex;
    justify-content: space-between;
padding-top: 30px;}

.checkbox_style_div form {
    display: flex;
    align-items: center;
}




/* can css */


td.fc-day {

background:#FFF !important;
font-family: 'Raleway', sans-serif;

}
td.fc-today {
	background:#FFF !important;
	position: relative;


}
.fc .fc-daygrid-event{border: 0;}
.fc-h-event .fc-event-title-container {
    background: #BBBBC1;
    padding: 6px 10px;
	
}
.fc-day{border-spacing: 0px;}
.fc .fc-daygrid-day-frame:after, 
.fc .fc-daygrid-day-events:after, 
.fc .fc-daygrid-event-harness:after,
.fc .fc-daygrid-day-frame:before, 
.fc .fc-daygrid-day-events:before, 
.fc .fc-daygrid-event-harness:before{display: none;}
.fc-h-event .fc-event-title{font-size: 15px;}
.fc .fc-scroller{overflow: hidden !important;}
.fc .fc-scroller-liquid-absolute{overflow: hidden auto !important;}
.fc th{border: 0 !important;}
.fc-theme-standard .fc-scrollgrid,
.fc td{border: 0 !important;}
.fc .fc-scrollgrid-section  td,
.fc .fc-scrollgrid-sync-table td,
.fc .fc-scrollgrid{border: 0;}
.fc .fc-scrollgrid-sync-table,
.fc .fc-col-header {    border: 0;border-collapse: separate;
    border-spacing: 10px;}
.fc .fc-col-header-cell{
	font-family: 'Raleway', sans-serif;
    background:#444444 !important;
	color:#FFF;
	font-size: 18px !important;
	font-weight: 700 !important;
	padding: 16px 1px;
	border: 0;
	
	}
	.fc .fc-col-header-cell .fc-scrollgrid-sync-inner a{color:#FFF;
		font-size: 18px !important;
		font-weight: 700 !important;
	text-decoration: none;
	}

	.fc-event-inner:nth-child(1){margin-top: 20px;}
.fc-event-inner {
	font-family: 'Raleway', sans-serif;
    background: #BBBBC1 !important;
    color: #444!important;
    font-size: 15px!important;
    
    padding: 20px 13px!important;
font-weight: 700;/* display: inline-block; *//* margin-top: 54px; */}

.fc {
	direction: ltr;
	text-align: left;
	}
	
.fc table {
	border-collapse: collapse;
	border-spacing: 0;
	}
	
html .fc,
.fc table {
	font-size: 1em;
	font-family: "Helvetica Neue",Helvetica;

	}
	
.fc td,
.fc th {
	padding: 0;
	vertical-align: top;
	}



/* Header
------------------------------------------------------------------------*/

.fc-header td {
	white-space: nowrap;
	padding: 15px 10px 0px;
}

.fc-header-left {
	width: 25%;
	text-align: left;
}
	
.fc-header-center {
	text-align: center;
	}
	
.fc-header-right {
	width: 25%;
	text-align: right;
	}
	
.fc-header-title {
	display: inline-block;
	vertical-align: top;
	margin-top: -5px;
}
.fc-today-button{display: none !important;}
	
.fc .fc-toolbar-chunk h2 {
	margin-top: 0;
	white-space: nowrap;
	font-size: 30px;
    font-weight: 100;
	font-weight: 700;
	font-family: 'Raleway', sans-serif;
	color: #444444;

}
.calender_wapper{margin-bottom: 60px; position: relative;}
.fc .fc-button-primary:focus{box-shadow: inherit !important;}
.fc .fc-button-primary:not(:disabled):active, 
.fc .fc-button-primary:not(:disabled).fc-button-active{
	background-color: transparent !important;
	color: #444444 !important;
	opacity: 0.7;
	border: none !important;
	box-shadow: inherit !important;
}
.fc .fc-toolbar-chunk button:hover,
.fc .fc-toolbar-chunk button {
    background-color: transparent;
	border: none;
	font-family: 'Raleway', sans-serif;
    color: #444444;
	font-size: 21px;
	padding: 0;
}
	span.fc-button {
    font-family: 'Raleway', sans-serif;
    border-color: #9675ce;
	color: #444444;
}
/* .fc-state-down, .fc-state-active {
    background-color: #9675ce !important;
	color: #FFF !important;
} */
.fc .fc-header-space {
	padding-left: 10px;
	}
	
.fc-header .fc-button {
	margin-bottom: 1em;
	vertical-align: top;
	}

/* ---------- new changes ---------- */
td.fc-header-center{
	display:none;
}
.fc-header span.fc-button{
	border: none;
}
	
/* buttons edges butting together */

.fc-header .fc-button {
	margin-right: -1px;
	}
	
.fc-header .fc-corner-right,  /* non-theme */
.fc-header .ui-corner-right { /* theme */
	margin-right: 0; /* back to normal */
	}
	
/* button layering (for border precedence) */
	
.fc-header .fc-state-hover,
.fc-header .ui-state-hover {
	z-index: 2;
	}
	
.fc-header .fc-state-down {
	z-index: 3;
	}

.fc-header .fc-state-active,
.fc-header .ui-state-active {
	z-index: 4;
	}


	
	
	
/* Content
------------------------------------------------------------------------*/
	
.fc-content {
	clear: both;
	zoom: 1; /* for IE7, gives accurate coordinates for [un]freezeContentHeight */
	}
	
.fc-view {
	width: 100%;
	overflow: hidden;
	}
	
	

/* Cell Styles
------------------------------------------------------------------------*/

    /* <th>, usually */
.fc-widget-content {  /* <td>, usually */
	border: 1px solid #e5e5e5;
	}
.fc-widget-header{
    border-bottom: 1px solid #EEE; 
}	


.fc-state-highlight > div > div.fc-day-number{
    margin: 4px;
}
	
.fc-cell-overlay { /* semi-transparent rectangle while dragging */
	background: #bce8f1;
	opacity: .3;
	filter: alpha(opacity=30); /* for IE */
	}
	


/* Buttons
------------------------------------------------------------------------*/

.fc-button {
	position: relative;
	display: inline-block;
	padding: 0 .6em;
	overflow: hidden;
	height: 1.9em;
	line-height: 1.9em;
	white-space: nowrap;
	cursor: pointer;
	}
	
.fc-state-default { /* non-theme */
	border: 1px solid;
	}

.fc-state-default.fc-corner-left { /* non-theme */
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
	}

.fc-state-default.fc-corner-right { /* non-theme */
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	}

/*
	Our default prev/next buttons use HTML entities like â€¹ â€º Â« Â»
	and we'll try to make them look good cross-browser.
*/

.fc-text-arrow {
	margin: 0 .4em;
	font-size: 2em;
	line-height: 23px;
	vertical-align: baseline; /* for IE7 */
	}

.fc-button-prev .fc-text-arrow,
.fc-button-next .fc-text-arrow { /* for â€¹ â€º */
	font-weight: bold;
	}
	
/* icon (for jquery ui) */
	
.fc-button .fc-icon-wrap {
	position: relative;
	float: left;
	top: 50%;
	}
	
.fc-button .ui-icon {
	position: relative;
	float: left;
	margin-top: -50%;
	
	*margin-top: 0;
	*top: -50%;
	}


.fc-state-default {
	border-color: #ff3b30;
	color: #ff3b30;	
}
.fc-button-month.fc-state-default, .fc-button-agendaWeek.fc-state-default, .fc-button-agendaDay.fc-state-default{
    min-width: 67px;
	text-align: center;
	transition: all 0.2s;
	-webkit-transition: all 0.2s;
}
/* .fc-state-hover,
.fc-state-down,
.fc-state-active,
.fc-state-disabled {
	color: #333333;
	background-color: #FFE3E3;
	} */

.fc-state-hover {
	color: #ff3b30;
	text-decoration: none;
	background-position: 0 -15px;
	-webkit-transition: background-position 0.1s linear;
	   -moz-transition: background-position 0.1s linear;
	     -o-transition: background-position 0.1s linear;
	        transition: background-position 0.1s linear;
	}

/* .fc-state-down,
.fc-state-active {
	background-color: #ff3b30;
	background-image: none;
	outline: 0;
	color: #FFFFFF;
} */

.fc-state-disabled {
	cursor: default;
	background-image: none;
	background-color: #FFE3E3;
	filter: alpha(opacity=65);
	box-shadow: none;
	border:1px solid #FFE3E3;
	color: #ff3b30;
	}

	

/* Global Event Styles
------------------------------------------------------------------------*/

.fc-event-container > * {
	z-index: 8;
	}

.fc-event-container > .ui-draggable-dragging,
.fc-event-container > .ui-resizable-resizing {
	z-index: 9;
	}
	 
.fc-event {
	border: 1px solid #FFF; /* default BORDER color */
	background-color: #FFF; /* default BACKGROUND color */
	color: #919191;               /* default TEXT color */
	font-size: 12px;
	cursor: default;
}
.fc-event.chill{
    background-color: #f3dcf8;
}
.fc-event.info{
    background-color: #c6ebfe;
}
.fc-event.important{
    background-color: #FFBEBE;
}
.fc-event.success{
    background-color: #BEFFBF;
}
.fc-event:hover{
    opacity: 0.7;
}
a.fc-event {
	text-decoration: none;
	}
	
a.fc-event,
.fc-event-draggable {
	cursor: pointer;
	}
	
.fc-rtl .fc-event {
	text-align: right;
	}

.fc-event-inner {
	width: 100%;
	height: 100%;
	overflow: hidden;
	line-height: 15px;
	}
	
.fc-event-time,
.fc-event-title {
	padding: 0 1px;
	font-weight: 700 !important;font-size: 18px;color: #444;opacity: 1;}
	
.fc .ui-resizable-handle {
	display: block;
	position: absolute;
	z-index: 99999;
	overflow: hidden; /* hacky spaces (IE6/7) */
	font-size: 300%;  /* */
	line-height: 50%; /* */
	}
	
	
	
/* Horizontal Events
------------------------------------------------------------------------*/

.fc-event-hori {
	border-width: 1px 0;
	margin-bottom: 1px;
	}

.fc-ltr .fc-event-hori.fc-event-start,
.fc-rtl .fc-event-hori.fc-event-end {
	border-left-width: 1px;
	/*
border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;
*/
	}

.fc-ltr .fc-event-hori.fc-event-end,
.fc-rtl .fc-event-hori.fc-event-start {
	border-right-width: 1px;
	/*
border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
*/
	}
	
/* resizable */
	
.fc-event-hori .ui-resizable-e {
	top: 0           !important; /* importants override pre jquery ui 1.7 styles */
	right: -3px      !important;
	width: 7px       !important;
	height: 100%     !important;
	cursor: e-resize;
	}
	
.fc-event-hori .ui-resizable-w {
	top: 0           !important;
	left: -3px       !important;
	width: 7px       !important;
	height: 100%     !important;
	cursor: w-resize;
	}
	
.fc-event-hori .ui-resizable-handle {
	_padding-bottom: 14px; /* IE6 had 0 height */
	}
	
	
	
/* Reusable Separate-border Table
------------------------------------------------------------*/

table.fc-border-separate {
	border-collapse: separate;
	border-spacing: 10px;
	}
	
.fc-border-separate th,
.fc-border-separate td {
	border-width: 1px 0 0 1px;
	}
	
.fc-border-separate th.fc-last,
.fc-border-separate td.fc-last {
	border-right-width: 1px;
	}
	
.fc-border-separate .fc-week .fc-first{
    border-left: 0;
}
.fc-border-separate .fc-week .fc-last{
    border-right: 0;
}
.fc-border-separate tr.fc-last th{
    border-bottom-width: 1px;
    border-color: #cdcdcd;
    font-size: 16px;
    font-weight: 300;
	line-height: 30px;
padding: 16px 1px;}
.fc-border-separate tbody tr.fc-first td,
.fc-border-separate tbody tr.fc-col-header-cell {
	border-top-width: 0;
	}
	
	

/* Month View, Basic Week View, Basic Day View
------------------------------------------------------------------------*/

.fc-grid th {
	text-align: center;
	}

.fc .fc-week-number {
	width: 22px;
	text-align: center;
	}

.fc .fc-week-number div {
	padding: 0 2px;
	}
	
.fc-grid .fc-day-number {
	float: left;
	padding: 0 2px;
	}
	
.fc-grid .fc-other-month .fc-day-number {
	opacity: 0.3;
	filter: alpha(opacity=30); /* for IE */
	/* opacity with small font can sometimes look too faded
	   might want to set the 'color' property instead
	   making day-numbers bold also fixes the problem */
	}
	
.fc-grid .fc-day-content {
	clear: both;
	padding: 2px 2px 1px; /* distance between events and day edges */
	}
	
/* event styles */
	
.fc-grid .fc-event-time {
	font-weight: bold;
	}
	
/* right-to-left */
	
.fc-rtl .fc-grid .fc-day-number {
	float: left;
	}
	
.fc-rtl .fc-grid .fc-event-time {
	float: right;
	}
	
	

/* Agenda Week View, Agenda Day View
------------------------------------------------------------------------*/

.fc-agenda table {
	border-collapse: separate;
	}
	
.fc-agenda-days th {
	text-align: center;
	}
	
.fc-agenda .fc-agenda-axis {
	width: 50px;
	padding: 0 4px;
	vertical-align: middle;
	text-align: right;
	white-space: nowrap;
	font-weight: normal;
	}

.fc-agenda .fc-week-number {
	font-weight: bold;
	}
	
.fc-agenda .fc-day-content {
	padding: 2px 2px 1px;
	}
	
/* make axis border take precedence */
	
.fc-agenda-days .fc-agenda-axis {
	border-right-width: 1px;
	}
	
.fc-agenda-days .fc-col0 {
	border-left-width: 0;
	}
	
/* all-day area */
	
.fc-agenda-allday th {
	border-width: 0 1px;
	}
	
.fc-agenda-allday .fc-day-content {
	min-height: 34px; /* TODO: doesnt work well in quirksmode */
	_height: 34px;
	}
	
/* divider (between all-day and slots) */
	
.fc-agenda-divider-inner {
	height: 2px;
	overflow: hidden;
	}
	
.fc-widget-header .fc-agenda-divider-inner {
	background: #eee;
	}
	
/* slot rows */
	
.fc-agenda-slots th {
	border-width: 1px 1px 0;
	}
	
.fc-agenda-slots td {
	border-width: 1px 0 0;
	background: none;
	}
	
.fc-agenda-slots td div {
	height: 20px;
	}
	
.fc-agenda-slots tr.fc-slot0 th,
.fc-agenda-slots tr.fc-slot0 td {
	border-top-width: 0;
	}
	
.fc-agenda-slots tr.fc-minor th.ui-widget-header {
	*border-top-style: solid; /* doesn't work with background in IE6/7 */
	}
	


/* Vertical Events
------------------------------------------------------------------------*/

.fc-event-vert {
	border-width: 0 1px;
	}

.fc-event-vert.fc-event-start {
	border-top-width: 1px;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
	}

.fc-event-vert.fc-event-end {
	border-bottom-width: 1px;
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
	}
	
.fc-event-vert .fc-event-time {
	white-space: nowrap;
	font-size: 10px;
	}

.fc-event-vert .fc-event-inner {
	position: relative;
	z-index: 2;
	}
	
.fc-event-vert .fc-event-bg { /* makes the event lighter w/ a semi-transparent overlay  */
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #fff;
	opacity: .25;
	filter: alpha(opacity=25);
	}
	
.fc .ui-draggable-dragging .fc-event-bg, /* TODO: something nicer like .fc-opacity */
.fc-select-helper .fc-event-bg {
	display: none\9; /* for IE6/7/8. nested opacity filters while dragging don't work */
	}
	
/* resizable */
	
.fc-event-vert .ui-resizable-s {
	bottom: 0        !important; /* importants override pre jquery ui 1.7 styles */
	width: 100%      !important;
	height: 8px      !important;
	overflow: hidden !important;
	line-height: 8px !important;
	font-size: 11px  !important;
	font-family: monospace;
	text-align: center;
	cursor: s-resize;
	}
	
.fc-agenda .ui-resizable-resizing { /* TODO: better selector */
	_overflow: hidden;
	}
	
thead tr.fc-first{
    background-color: #f7f7f7;
}
body .fc-header-toolbar{
    background-color: #FFFFFF;
    border-radius: 6px 6px 0 0;
	display: flex;
    justify-content: center !important;
}
.fc .fc-day-other .fc-daygrid-day-top{opacity: 0.5;}
.fc .fc-daygrid-day-top a,
.fc .fc-day-other .fc-daygrid-day-top,
.fc .fc-day-other .fc-daygrid-day-top a,
.fc-week .fc-day > div .fc-day-number{
    font-size: 18px;
    margin: 2px;
    min-width: 19px;
    padding: 14px;
    text-align: center;
    /* width: 30px;
    height: 30px; */
	font-weight: 700;
	font-family: 'Raleway', sans-serif;
	color: #444444;
	text-decoration: none;

}
.fc-border-separate th, .fc-border-separate td{
	border: none;
}
.fc-sun, .fc-sat{
    color: #b8b8b8;
}

.fc-week .fc-day:hover .fc-day-number{
    /* background-color: #B8B8B8; */
    border-radius: 50%;
    /* color: #FFFFFF; */
    transition: background-color 0.2s;
}
.fc-week .fc-day.fc-state-highlight:hover .fc-day-number{
    background-color:  #ff3b30;
}
.fc-button-today{
    border: 1px solid rgba(255,255,255,.0);
}
.fc-view-agendaDay thead tr.fc-first .fc-widget-header{
    text-align: right;
    padding-right: 10px;
}
/* --------- new changes */
span.fc-button.fc-button-today.fc-state-default.fc-corner-left.fc-corner-right {
    display: none;
}
/*!
 * FullCalendar v1.6.4 Print Stylesheet
 * Docs & License: http://arshaw.com/fullcalendar/
 * (c) 2013 Adam Shaw
 */

/*
 * Include this stylesheet on your page to get a more printer-friendly calendar.
 * When including this stylesheet, use the media='print' attribute of the <link> tag.
 * Make sure to include this stylesheet IN ADDITION to the regular fullcalendar.css.
 */
 
 
 /* Events
-----------------------------------------------------*/
 
.fc-event {
	background: #fff !important;
	color: #000 !important;
	}
	
/* for vertical events */
	
.fc-event-bg {
	display: none !important;
	}
	
.fc-event .ui-resizable-handle {
	display: none !important;
	}
	
@media (max-width: 650px){
	.fc-h-event .fc-event-title {
		font-size: 13px;
	}
	.fc .fc-view-harness-active > .fc-view{height: 400px;}
	.fc .fc-scrollgrid{width: 970px !important;}
	.fc .fc-scrollgrid-sync-table, .fc .fc-col-header {
		width: 950px !important;
		overflow: auto !important;
	}
	.fc .fc-col-header-cell{padding: 10px 4px;}
	.fc .fc-daygrid-day-top a, 
	.fc .fc-day-other .fc-daygrid-day-top,
	 .fc .fc-day-other .fc-daygrid-day-top a, 
	.fc-week .fc-day > div .fc-day-number,
	.fc .fc-col-header-cell .fc-scrollgrid-sync-inner a{font-size: 14px !important;}
	.fc-ltr .fc-event-hori.fc-event-end, .fc-rtl .fc-event-hori.fc-event-start{    width: 170px !important;}
	body .main_event_heading_text {
		float: left;
		width: 100%;
	}
	body .checkbox_style_div .container-checkbox{font-size: 14.5px;}
	body .fc-event-time, body .fc-event-title{font-size: 15px;}
	.fc-border-separate tr th{width: auto !important;}
	.fc .fc-toolbar-chunk h2{font-size: 25px;}
	table.fc-border-separate {
		width: 650px !important;
		}
		
	.fc-view {
		width: 100%;
		overflow-y: hidden;
		overflow-x: auto;
	}
    .checkbox_style_div {
		margin-top: 20px;
	}
	.fc-week .fc-day > div .fc-day-number,
	body .fc-col-header-cell{font-size: 16px !important;}
	.checkbox_style_div form,	
	.event_row_checker .col-sm-12{flex-wrap: wrap;}
	
}

/* mubeen work */
.fc-event-title.fc-sticky {
    white-space: break-spaces;
}
.fc .fc-event-selected:after, .fc .fc-event:focus:after{display: none;}
.fc .fc-event-selected, 
.fc .fc-event:focus{box-shadow: inherit !important;}

.div_heading_event_bg{background-color: #BBBBC1;padding: 8px 13px; width: 100%;}
span.heading_event {font-weight: 700;font-size: 15px;line-height: 18px;color: #444444;position: relative;white-space: normal;}
.righ_event_img {text-align: right;width: auto;padding: 10px 0px;position: absolute;right: -2px;top: -6px;}
.wapper_event {background-color: #fff;display: flex;align-items: center;flex-wrap: wrap;}
.wapper_event span {display: inline-block;width: 100%;}
.wapper_event span.date_event {text-align: left;padding: 12px 18px;font-weight: 700;font-size: 18px;line-height: 21px;color: #444444;}	
span.time_event {margin-top: 3px;font-weight: 400;font-size: 13px;line-height: 15px;color: #444444;float: left;width: 100%;}
.day_wapper_event .div_heading_event_bg{background-color: #EEE6B8;}


.datePiker{
    display: flex;
    justify-content: end;
    position: absolute;
    left: -47px;
    top: 0px;
    /* z-index: 99999; */
}
@media(max-width:768px){
	.datePiker{
    justify-content: center;
    position: inherit;
    margin-bottom: 16px;
	}
	
}

