/* -------- header section css start here -------- */

.mobile_hidden_s{ display: none;}
.header .editor_menu {display: flex; align-items: center;justify-content: space-between; height: 60px;}
.header ul {margin-bottom: 0px;}
.header .editor_menu ul{list-style-type: none;}
.header .editor_menu ul li{display: inline-block;color: #FFFFFF;font-size:13px ;font-weight: 500;line-height: 16.56px;}
body .header .editor_board_header ul li a{cursor: pointer; width: 135px;padding-top: 10px;text-decoration: none; color:#ffffff;line-height: 16.56px;font-size: 13px; text-align: center;font-weight: 700;border: 1px solid #FFFFFF;border-radius: 4px;}
.header .editor_board_header{background-color: #373737;}
.header .editor_menu .menu_icons ul li{margin-left: 62px;}
.header .editor_menu .menu_icons ul li:first-child{margin-left: 36px;}
.header .editor_menu .menu_icons ul li span{margin-left: 10px;
    cursor: pointer;
}
.header .left_side_drop_down{display: flex; align-items: center;}
.header .left_side_drop_down .drop_down{background-color:#494949; height: 60px; display: flex; align-items: center; padding-right: 14px;}
.header .left_side_drop_down .drop_down ul li{margin-left: 14px;}
header.header{width: calc(100% - 270px);left: 270px!important;}
.header .editor_menu .menu_button ul li{margin-right: 15px; }
.header .editor_menu .menu_button ul li a:hover{background-color:#FFFFFF;color: #232323;}
body .header .editor_board_header .drop_down ul.dropdown li span { max-width: 70px; display: inline-block; width: 100%; }
body .header .editor_board_header .drop_down a.dropdown-toggle img{margin-top: 0}
.header .left_side_drop_down .drop_down li img {
    margin-top: 4px;
}
.editor_rightside .text_editor .text_editor_area h2 {margin-bottom: 20px; color: white; text-align: right; font-style: normal; font-weight: 400; font-size: 19px; line-height: 26px;margin-right: 15px;}

/* -------- right side css start here -------- */

.text_editor_area img { width: 100%; height: 100%; }
.editor_rightside{background: #232323!important;}
.text_editor{text-align: center;}
.text_editor_area{
    width: 80%;height: 80vh;margin: 112px auto;
}
.editor_board_box{
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.5);
    /* width: 80%;
    height: 80vh;
    margin: 112px auto; */
}
.edit_post_waper{display: none}
body .header .editor_board_header .drop_down a.dropdown-toggle { cursor: pointer; width: auto; border: 0; padding-top: 12px;}
body .header .editor_board_header .drop_down ul.dropdown li {width: 100%;padding: 10px 1px;text-align: center;}
body .header .editor_board_header .drop_down ul.dropdown {position: absolute;left: 0;background-color: #494949;max-width: 248px;width: 100%;}
.drop_down .dropdown li{font-style: normal; font-weight: 700; font-size: 13px; line-height: 127.4%;}
/* .inner_dropdown li span { margin-left: 14px; } */
.inner_dropdown li img{margin-right: 14px;}


/* -------- Photos Popup -------- */


.photos_popup .checkbox_style .chose_imagebox_inner::-webkit-scrollbar {
    background-color:#fff;
    width:9px
}

.photos_popup .checkbox_style .chose_imagebox_inner::-webkit-scrollbar-track {
    background-color:#fff;
    position: relative;
    z-index: 99;
}

 .photos_popup .checkbox_style .chose_imagebox_inner::-webkit-scrollbar-track:hover {
  background-color:#fff;
    position: relative;
    z-index: 99;
}

 
 .photos_popup .checkbox_style .chose_imagebox_inner::-webkit-scrollbar-thumb {
    background-color:#C4C4C4;
    border-radius:16px;
    border:2px solid #C4C4C4;
    position: relative;
    z-index: 99;
}
.btn-check:focus+.btn, .btn:focus{box-shadow: inherit !important;}
.photos_popup .modal-dialog{display: flex;justify-self: center;align-items: center;height: 100%;}
 
 .photos_popup .checkbox_style .chose_imagebox_inner::-webkit-scrollbar-thumb:hover {
  background-color:#C4C4C4;
    border:9px solid #C4C4C4;
    position: relative;
    z-index: 9;
}
 .photos_popup .checkbox_style .chose_imagebox_inner::-webkit-scrollbar-button {
    display:none;
    z-index: 99;
}
.photos_popup .show_bottom{position: relative;}
.photos_popup .show_bottom:before {
    content: "";
    position: absolute;
    bottom: 85px;
    width: 100%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 40.1%);
    height: 53px;
    z-index: 9;
}
.photos_popup .checkbox_style .chose_imagebox_inner{;overflow-y: auto;margin-bottom: 20px;overflow-x: hidden;padding-left: 0;height: 637px;}
.done_button button.btn.login_btn {max-width: 170px;margin-left: auto;}
.photos_popup .modal .modal-dialog{width: 100%;max-width: 1270px;}
.chose_imagebox_inner .custom_checkbox {float: left;width: 18.66%;margin-right: 15px;position: relative;margin-bottom: 15px;}
.chose_imagebox_inner .custom_checkbox span.imgcover {width: 200px;height: 200px;border-radius: 10px;overflow: hidden;display: inline-block;}
.chose_imagebox_inner .custom_checkbox span.imgcover img {width: 100%;height: 100%;object-fit: cover;}
.chose_imagebox_inner {display: flex;flex-wrap: wrap;justify-content: left;}
.photos_popup .modal.fade .modal-dialog .modal-body{padding: 65px 45px;}

.custom_checkbox input[type="checkbox"]{
    display: none;
   }
   .custom_checkbox input[type="checkbox"] + label{
    display: inline-block;
    cursor: pointer;
    line-height: 1em;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    width: 100%;
    padding: 0;
   }
   .custom_checkbox input[type="checkbox"] + label:before,
   .custom_checkbox input[type="checkbox"] + label:after{
    content: '';
    position: absolute;
    top: 16px;
    
    width: 25px;
    height: 25px;
    text-align: center;
    color: white;
    font-family: Times;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
   right: 26px;}
   .custom_checkbox input[type="checkbox"] + label:before {
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    border: 1px solid transparent;
   background: rgba(196, 196, 196, 0.5);
border: 3px solid #FFFFFF;
border-radius: 22px;}
   .custom_checkbox input[type="checkbox"] + label:hover:before {
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    background: green;
   }
   .custom_checkbox input[type="checkbox"]:checked + label:before {
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
        
   background: green;}
 


/* -------- Screen Size 1820px css -------- */
@media(max-width:1820px){
    .header .editor_menu .menu_icons ul li {margin-left: 30px;}
    .header .editor_menu .menu_icons ul li:first-child {margin-left: 12px;}
    }
    /* -------- Screen Size 1650px css -------- */
    @media(max-width:1650px){
        body .header .editor_board_header ul li a{width: 100px;}
        .header .editor_menu ul{padding-left: 0px;}
        body .header .drop_down , .menu_button ul{padding-left: 0px;}
        .header .editor_menu .menu_button ul li {margin-right: 5px;}
        .header .editor_menu .menu_button ul li:last-child {margin-right: 10px;}
        body .header .editor_board_header .drop_down ul.dropdown {max-width: 216px;}
        
    }
    /* -------- Screen Size 1440px css -------- */
    @media(max-width:1440px){
        .header .editor_menu .menu_icons ul li{margin-left: 16px;}
        .header .left_side_drop_down .drop_down ul li{margin-left: 4px;}
        .header .editor_menu .menu_icons ul li span{margin-left: 6px;}
        .header .left_side_drop_down .drop_down ul li:first-child{margin-left: 10px;}
        body .header .editor_board_header .drop_down ul.dropdown {max-width: 182px;}
        .inner_dropdown li img{margin-right: 4px;margin-left: 20px;}
        body .inner_dropdown li:first-child{margin-left: 4px!important}
    
        
    }
    /* -------- Screen Size 1280px css -------- */
    @media(max-width:1280px){
        .chose_imagebox_inner .custom_checkbox{width: 18%;}
        header.header{width: calc(100% - 270px);left: 270px!important;}
        .menu_icons ul li img{width: 17px;}
        body .header .editor_board_header ul li a{font-size: 11px;width: 79px;height: 34px;padding-top: 7px;}
        
    }
    /* -------- Screen Size 1240px css -------- */
    @media(max-width:1240px){
        header.header{width: calc(100% - 240px);left: 240px!important;}
    }
    /* -------- Screen Size 1130px css -------- */
    @media(max-width:1160px){
        
        .chose_imagebox_inner .custom_checkbox{width: 23%;}
        body .header .editor_board_header ul li a { font-size: 10px; width: 70px; height: 33px;}
        .header .editor_menu .menu_icons ul li span{margin-left: 3px;}
        .header .editor_menu ul li{font-size: 10px;}
        .header .editor_menu .menu_button ul li { margin-right: 2px; }
        .header .editor_menu .menu_icons ul li { margin-left: 10px; }
        .menu_icons ul li img{width: 14px;}
        .header .left_side_drop_down .drop_down{padding-right: 6px;}
        body .header .editor_board_header .drop_down ul.dropdown {max-width: 150px;}
        .inner_dropdown li img{margin-right: 0px;margin-left: 29px;}
    
    }
    /* -------- Screen Size 980px css -------- */
    @media(max-width:980px){
        .mobile_hidden_s{display: block;}
        .chose_imagebox_inner .custom_checkbox {
            width: 200px;
            margin: auto;
            margin-bottom: 15px !important;
        }
        body .chose_imagebox_inner{justify-self: center;}
        .header .editor_board_header .edit_post_waper .dropdown-toggle{ background:transparent; transform: matrix(-1, 0, 0, 1, 0, 0); color: #fff; border-radius: 100%; display: block; text-decoration: none; background-image: url(../../../public/images/dots.svg); background-repeat: no-repeat; background-position: center; display: inline-block; cursor: pointer; position: absolute; right: 0; top: -16px; }
        .header .editor_board_header .edit_post_waper ul li a { border: 0; background-color: transparent; text-align: left; }
        .header .editor_board_header .edit_post_waper ul.dropdown {    top: 33px;padding: 10px;position: absolute;left: 0;width: 100%;background-color: #232323;min-width: 184px;}
        .edit_post_waper {position: relative;width: 100%;}
        .header .editor_board_header .edit_post_waper ul li{width: 100%}
         .header .editor_board_header .menu_button{display: none}
        .header .editor_board_header .left_side { margin-right: 16px; padding-top: 10px; }
        .header .editor_board_header .mobile_narbar span.dropdown-toggle { color: #fff; }
        .edit_post_waper .dropdown{display: none}
        .mobile_hidden{display: none}
        .edit_post_waper { display: block; position: absolute; right: 0; }
        header.header { width: 100%; left: 0!important; }
        .editor_rightside .text_editor .text_editor_area h2 {font-size: 18px; line-height: 22px; }
        body .header .editor_board_header .drop_down ul.dropdown{left: 49px;}
        .inner_dropdown li img{margin-right: 0px;}
    
    
    }
    
    @media(max-width:500px){
    
    .photos_popup .checkbox_style .chose_imagebox_inner {height: 550px;}
    .chose_imagebox_inner .custom_checkbox {width: 170px;}
    .chose_imagebox_inner .custom_checkbox span.imgcover {width: 170px;height: 170px;}
    .custom_checkbox input[type="checkbox"] + label:before, .custom_checkbox input[type="checkbox"] + label:after{width: 20px;height:20px;top: 14px;right: 18px;}
    .custom_checkbox input[type="checkbox"] + label:before{    border: 2px solid #FFFFFF;}
    .photos_popup .modal .modal-dialog{max-width: 360px;margin: auto;}
    
    }








/* share design board  start*/
.share_form .modal.fade .modal-dialog {max-width: 468px;width: 100%;display: flex;align-items: center;justify-content: center;height: 100%;}
.share_form .user_card .justify-content-center{flex-wrap: wrap;}
.share_form .user_card .justify-content-center h3 {font-weight: 700;font-size: 22px;line-height: 26px;text-align: center;color: #444444;padding: 20px 0;width: 100%;}
.share_form .user_card{width: 100%;}
.share_form .form-group{padding: 0;}
body .share_form input,
body .share_form .form-group textarea{padding: 4px 15px;font-size: 13px;}
body .share_form .form-group textarea:focus {color: #212529;background-color: #fff;border-color: #444444;outline: 0;box-shadow: 0 0 0 0.25rem rgb(68 68 68 / 5%);}
body .share_form .checkbox_style{padding-top: 10px;}
body .share_form  span.plus {position: absolute;right: 9px;bottom: 6px;font-size: 20px;cursor: pointer;}
body .share_form .minus{font-size: 20px;position: relative; top: 1px;cursor: pointer;right: 9px;}

/* share design board end*/

body .sketch-picker {
    padding: 10px 13px 20px 13px!important; 
}
.color_picker_e_b{
    position: relative;
}
.font_size_d_d{
    width: 70px!important;
}

.flip_d_d{
    width: 100px!important;
}
.flip_d_d li{
    color:black!important;
    margin-left:10px!important;
    display:block!important;
    cursor: pointer;
}

.font_size_d_d li{
    color:black!important;
    margin-left:10px!important;
    display:block!important;
    cursor: pointer;
}

.flip_d_d li:hover{color: #75757594!important;}
.font_size_d_d li:hover{color: #75757594!important;}

.board_detail {
    display: flex;
}

.editor_rightside .text_editor .text_editor_area h2 strong {
    font-weight: 700;
    display: inline-block;
    margin-right: 7px;
}