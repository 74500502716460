.map_div {
  width: 100%;
  border-radius: 10px;
  height: 100%;
  overflow: hidden;
}

.map_div iframe {
  height: 984px;
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
}
  div.box {
    text-align: center;
    width: 48px;
    border-radius: 3px;
    padding: 3px;
    background-image: url(../../../public/images/heart.svg);
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
  }
  .box.entypo-thumbs-down{
    background-image: url(../../../public/images/heartred.svg);
    background-size: 31px;
  }
  div.box {
    font-size: 0;
    text-decoration: none;
    color: #000;
  }
 
  body .location_row {
    justify-content: space-between;
    align-items: self-start;
}

  .loaction_section .search_bar .input_field {
    box-sizing: border-box;
    border-radius: 5px;
    height: 42px;
    padding: 10px 20px;
    width: 100%;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #444444;
    outline: none;
    border: 0;
  }
  
  .loaction_section .submit_button{
    position: absolute;
    right: 8px;
    top: 13px;
    background-color: transparent;
    width: 20px;
    height: 20px;
    background-image: url(../../../public/images/find-icon.png);
    font-size: 0;
    border: 0;
    background-size: 16px;
    background-repeat: no-repeat;
  }
  .loaction_section .search_bar {
    margin-top: 0;
    margin-bottom: 0; 
    border: 1px solid #CCCCCC;
    box-sizing: border-box;
    border-radius: 5px;
    height: 44px;
    width: 100%;
    position: relative;}
  body .find_row .col-sm-12{
    display: flex;
    align-items: center;}
  body .find_row .col-sm-12 h3 {
    width: 100%;
    max-width: 230px;
    margin-bottom: 0;
}
.location_row .col-sm-6{
  display: flex;
  flex-wrap: wrap;
  width: 48%;
}
.location_row .col-sm-6 .single_location_div {
  background-color: #fff;
  border: 1px solid #707070;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 0px 15px 30px;
  width: 44%;
  padding: 70px 40px 35px;
  position: relative;
  text-align: center;
}
div#holder {
  width: 100%;
  display: flex;
  position: absolute;
  right: 20px;
  top: 20px;
  justify-content: end;
}
.location_company_logo{
  text-align: center;
  width: 100%;
  min-height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 11px;
  max-width: 140px;
}

.location_company_name h3{
  font-weight: 700;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  color: #444444;
  min-height: 40px;
}

.location_company_name p a,
.location_company_name p{
 font-weight: 500;
 font-size: 13px;
 line-height: 127.4%;
 text-align: center;
 color: #757575;
 text-decoration: none;
 display: inline-block;
 width: 100%;
}

.location_company_name p span{
  display: inline-block;
  width: 100%;
  padding-bottom: 6px;
}

.location_company_name .btn_wapper a.same_btn{
  width: 125px;
  height: 33px;
  font-size: 13px;
}

.border-0 {
  border:0;
}

@media (max-width: 1450px) {
.location_company_logo img {
  width: 100%;
  object-fit: contain;
}
.location_row .col-sm-6 .single_location_div{
  width: 43%;
}
.location_row .col-sm-6{
  margin-right: 0;
}

}

.location_company_logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}


@media (max-width: 1280px) {

  .location_row .col-sm-6 .single_location_div{
    width: 100%;
  }

  .location_company_logo{
    max-width: 140px;
    margin: auto;
    min-height: 115px;
  }

  body .map_div iframe {
    height: 626px;
  }

}

@media (max-width: 1024px) {

  body .map_div iframe {
    height: 384px;
}

}
@media (max-width: 980px) {

  body .location_row{flex-direction: column-reverse;}
  .location_row .col-sm-6 {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
body .location_row{
  margin-bottom: 30px;
}

.location_company_name {
  width: 100%;
}

}

@media (max-width: 650px) {

  body .find_row .col-sm-12{
    flex-wrap: wrap;
  }
  body .find_row .col-sm-12 h3{
    margin-bottom: 30px;
  }
body .loaction_section {
  margin-right: 0;
}

}
body .full-with-search-bar .search_bar input, 
body .full-with-search-bar .search_bar {
  max-width: 100%;
}