.create_project_post_div .project_board_row {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
}

.create_project_post_div .project_board_row .project_board_list {
    float: left;
    width: 23%;
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    box-sizing: border-box;
    border-radius: 10px;
    margin-right: 30px;
    margin-bottom: 30px;
    /* overflow: hidden; */
    position: relative;
}

.create_project_post_div .project_board_row .project_board_list:nth-child(4n) {
    margin-right: 0;
}
.yi_three_btn_section .col-md-4 {
    margin-right: 20px;
    width: 31.33%;
}

.yi_three_btn_section .col-md-4:last-child {
    margin-right: 0;
}
.create_project_post_div .project_board_row .project_board_list .image_project {
    width: 100%;
    height: 191px;
    overflow: hidden;
    border-radius: 10px 10px 0px 0px;
}

.create_project_post_div .project_board_row .project_board_list .image_project img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.create_project_post_div .project_board_row .project_board_list .text_project {
    padding: 20px 30px;
}

.create_project_post_div .project_board_row .project_board_list .edit_post {
    position: relative;
    bottom: -28px;
    float: right;
}

.create_project_post_div .project_board_row .project_board_list .edit_post > a {
    transform: matrix(-1, 0, 0, 1, 0, 0);
    color: #fff;
    border-radius: 100%;
    display: block;
    text-decoration: none;
    background-image: url(../../../public/images/dots.svg);
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    cursor: pointer;
}
.create_project_post_div .project_board_row .project_board_list .text_project span.date {
    font-weight: 700;
    font-size: 13px;
    line-height: 127.4%;
    color: #757575;
    padding-bottom: 10px;
    display: block;
}
.create_project_post_div .project_board_row .project_board_list .text_project h4 {
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    color: #444444;
}
.create_project_post_div .project_board_row .project_board_list .text_project p {
    font-weight: 400;
    font-size: 13px;
    line-height: 127.4%;
    color: #757575;
    margin-bottom: 10px;
}
.create_project_post_div .project_board_row .project_board_list .text_project p.bio {
    margin-bottom: 0;
}


/**** ****/
ul.dropdown {
  display: none;
  position: absolute;
  top: 100%;
  margin-top: 5px;
  padding: 5px 5px 0 0;
  z-index: 999;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  left: -117px;
  width: 123px;
  z-index: 9999;
  overflow: hidden;
  background-color: #fff;
  }
ul.dropdown li {list-style-type: none;padding: 11px 16px;}
ul.dropdown li a:hover{color: #75757594;}
ul.dropdown li a {font-weight: 500;
    font-size: 14px;
    line-height: 127.4%;
    text-decoration: none;
    color: #757575;
     display: block; }
body .dropdown-toggle::after{display: none;}

/* new changes */

.search_bar{position: relative;}
.form_list {
    background-color: #ffff;
    border-radius: 5px;
    border: 1px solid #00000040;
    top: 45px;
    position: absolute;
    width: 100%;
    z-index: 1;
    padding: 10px;
    /* height: 150px; */
    max-height: 400px;
    overflow: auto;
}
.form_list ul li{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 25px;
    color: #444444;
}
.form_list h2 {
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: #444444;
}
.form_list ul {
    list-style-type: none;
    padding-left: 10px;
}

@media (max-width: 1450px) {
   
    .create_project_post_div .project_board_row .project_board_list{margin-right: 25px;margin-bottom: 25px;}
    .yi_three_btn_section .col-md-4 {
        margin-right: 20px;
        width: 31%;
    }
}

@media (max-width: 1280px) {
    .create_project_post_div .project_board_row .project_board_list.div_added_new_project{
        height: 300px;
    }
    .yi_three_btn_section .col-md-4 {
        margin-right: 20px;
        width: 31%;
    }
    .create_project_post_div .project_board_row .project_board_list:nth-child(4n){margin-right: 28px;}
    .create_project_post_div .project_board_row .project_board_list{width: 29%;}
}

@media (max-width: 980px) {
    .create_project_post_div .project_board_row .project_board_list:nth-child(4n){margin-right: 0;}
    .create_project_post_div .project_board_row .project_board_list{width: 100%;margin-right: 0;} 
}

@media (max-width: 650px) {

    .yi_three_btn_section .col-md-4{width: 100%;margin-right: 0;margin-bottom: 20px;}
}


/* popup style */

.chose_box .form-group {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.chose_box_inner{
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
}
.chose_box .form-group .custom_radio {
    float: left;
    width: 22%;
    margin-right: 18px;
    margin-top: 15px;
    position: relative;
}
.chose_box .form-group .custom_radio .imgcover{box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);display: inline-block; 
    /* max-width: 143px; */
    height: 118px; width: 100%;overflow: hidden;}
.chose_box .form-group .custom_radio img{width: 100%;height: 100%;object-fit: cover;}
.project_form .modal-dialog .text_wapper_inner{padding: 0 10px;}
.project_form .modal-dialog .text_wapper_inner p{
font-weight: 400;
font-size: 15px;
line-height: 127.4%;
color: #494949;
padding-bottom: 20px;
}
.project_form .modal-dialog .modal-body {
    padding: 60px 55px;
}

.project_form .user_card .row .form-group {
    padding: 0 10px;
}
.project_form .user_card .row .form-group textarea{height: 76px;}

.project_form .modal-dialog {
    width: 100%;
    max-width: 940px;
    margin: auto;
}

  .custom_radio input[type="radio"]{
   display: none;
  }
  .custom_radio input[type="radio"] + label{
   display: inline-block;
   cursor: pointer;
   line-height: 1em;
   -webkit-transition: all 0.3s ease-in-out;
   transition: all 0.3s ease-in-out;
   width: 100%;
   padding: 0;
  }
  .custom_radio input[type="radio"] + label:before,
  .custom_radio input[type="radio"] + label:after{
   content: '';
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 96%;
   text-align: center;
   color: white;
   font-family: Times;
   -webkit-transition: all .3s ease;
   transition: all .3s ease;
  }
  .custom_radio input[type="radio"] + label:before {
   -webkit-transition: all .3s ease;
   transition: all .3s ease;
   border: 1px solid transparent;
  }
  .custom_radio input[type="radio"] + label:hover:before {
   -webkit-transition: all .3s ease;
   transition: all .3s ease;
       border: 3px solid green;
  }
  .custom_radio input[type="radio"]:checked + label:before {
   -webkit-transition: all .3s ease;
   transition: all .3s ease;
       border: 3px solid green;
  }

  @media (max-width: 650px) {

    .chose_box .form-group .custom_radio .imgcover{max-width: 100%;}
    .chose_box .form-group .custom_radio{width: 100%;margin-right: 0;}
    .project_form .modal-dialog .modal-body {
        padding: 55px 35px;
    }   
  }


  .inner_container {
    position: relative;
    max-width: 1525px;
    width: 100%;
    margin: auto;
}

.create_project_board .create_project_post_div{

position: relative;
max-width: 1561px;
width: 100%;
margin: auto;

}

.create_project_post_div .project_board_row .project_board_list.div_added_new_project {
    background: transparent;
    border: 1px solid #CCCCCC;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 330px;
}

.create_project_post_div .project_board_row .project_board_list.div_added_new_project .image_project {
    display: flex;
    justify-content: center;
    width: auto;
    flex-wrap: wrap;
    height: auto;
    padding: 0 16px;
}

.create_project_post_div .project_board_row .project_board_list.div_added_new_project .image_project img {width: 62px;height: 62px;}
.create_project_post_div .project_board_row .project_board_list.div_added_new_project .image_project h4 {
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: rgba(68, 68, 68, 0.7);
    width: 100%;
    margin-top: 14px;
    margin-bottom: 0;
   
}
.create_project_post_div .project_board_row .project_board_list.div_added_new_project a{
    text-decoration: none;
}